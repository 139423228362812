import { createSlice } from "@reduxjs/toolkit";
import { PRODUCTS } from "constants/common";
import { ACCESSORY_SUBTYPE_S, ACCESSORY_TYPE_S, BODY_CONDITION_S, BODY_TYPE_S, CAR_COLOR_S, CAR_DETAILS_S, CAR_MODEL_S, CITY_S, COUNTRY_F, COUNTRY_S, GET_CMS_S, GET_FAQ_S, GET_HOME_S, GET_SEARCH_RESULT_F, GET_SEARCH_RESULT_S, HORSE_POWER_S, INTERIOR_COLOR_S, MAKE_S, MECHENICAL_CONDITION_S, USAGE_S } from 'constants/redecuerType';
import { IAccessoryTypeSubTypeList, ICountry, IPostCarDetails, IPostDropdown, ISearchResultAccesories, ISearchResultCar } from 'Types/Entity/MetaEntity';


export interface IMetaSlice {
  stepperInputs: {
    city: IPostDropdown[];
    BodyCondition: IPostDropdown[];
    BodyType: IPostDropdown[];
    CarColor: IPostDropdown[];
    CarModel: IPostDropdown[];
    HorsePower: IPostDropdown[];
    InteriorColor: IPostDropdown[];
    Make: IPostDropdown[];
    MechenicalCondition: IPostDropdown[];
    Usage: IPostDropdown[];
    Category: IPostDropdown[];
  }
  countryOption: ICountry[],
  accessoryType: IAccessoryTypeSubTypeList[];
  accessorySubType: IAccessoryTypeSubTypeList[];
  searchResultCar: {
    list: ISearchResultCar[],
    totalPages: number,
    details: IPostCarDetails;
  },
  searchResultAccessories: {
    list: ISearchResultAccesories[],
    totalPages: number
  }
  homeListing: {
    usedCar: [],
    newCar: [],
    autoPart: []
  }
  cmsValue: ''
  faq: []
}

const initialState: IMetaSlice = {
  stepperInputs: {
    city: [{ label: 'No data', value: '' }],
    BodyCondition: [{ label: 'No data', value: '' }],
    BodyType: [{ label: 'No data', value: '' }],
    CarColor: [{ label: 'No data', value: '' }],
    CarModel: [{ label: 'No data', value: '' }],
    HorsePower: [{ label: 'No data', value: '' }],
    InteriorColor: [{ label: 'No data', value: '' }],
    Make: [{ label: 'No data', value: '' }],
    MechenicalCondition: [{ label: 'No data', value: '' }],
    Usage: [{ label: 'No data', value: '' }],
    Category: [{ label: 'No data', value: '' }]
  },
  countryOption: [],
  accessoryType: [],
  accessorySubType: [],
  searchResultCar: {
    list: [],
    totalPages: 0,
    details: {}
  },
  searchResultAccessories: {
    list: [],
    totalPages: 0
  },
  homeListing: {
    usedCar: [],
    newCar: [],
    autoPart: []
  },
  cmsValue: '',
  faq: []
};
// Reducer
const metaSlice = createSlice({
  name: "post",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GET_SEARCH_RESULT_S, (state, action: any) => {
      if (action.payload.postType === PRODUCTS.accessories.value) {
        state.searchResultAccessories = {
          list: action?.payload?.data || [],
          totalPages: action?.payload?.totalPages
        }
      } else {
        state.searchResultCar = {
          list: action?.payload?.data || [],
          totalPages: action?.payload?.totalPages,
          details: {}
        }
      }
    });

    builder.addCase(GET_SEARCH_RESULT_F, (state, action: any) => {
      state.searchResultCar = {
        list: [],
        totalPages: 0,
        details: {}
      }
      state.searchResultAccessories = {
        list: [],
        totalPages: 0
      }
    });

    builder.addCase(BODY_CONDITION_S, (state, action: any) => {
      state.stepperInputs.BodyCondition = action.payload?.map((item) => {
        return ({ label: item?.name, value: item?.id })
      });
    });

    builder.addCase(BODY_TYPE_S, (state, action: any) => {
      state.stepperInputs.BodyType = action.payload?.map((item) => {
        return ({ label: item?.name, value: item?.id })
      });
    });

    builder.addCase(CAR_COLOR_S, (state, action: any) => {
      state.stepperInputs.CarColor = action.payload?.map((item) => {
        return ({ label: item?.name, value: item?.id })
      });
    });

    builder.addCase(CAR_MODEL_S, (state, action: any) => {
      state.stepperInputs.CarModel = action.payload?.map((item) => {
        return ({ label: item?.name, value: item?.id })
      });
    });

    builder.addCase(CITY_S, (state, action: any) => {
      state.stepperInputs.city = action.payload?.map((item) => {
        return ({ label: item?.name, value: item?.id })
      });
    });

    builder.addCase(HORSE_POWER_S, (state, action: any) => {
      state.stepperInputs.HorsePower = action.payload?.map((item) => {
        return ({ label: item?.name, value: item?.id })
      });
    });

    builder.addCase(INTERIOR_COLOR_S, (state, action: any) => {
      state.stepperInputs.InteriorColor = action.payload?.map((item) => {
        return ({ label: item?.name, value: item?.id })
      });
    });

    builder.addCase(MAKE_S, (state, action: any) => {
      state.stepperInputs.Make = action.payload?.map((item) => {
        return ({ label: item?.name, value: item?.id })
      });
    });

    builder.addCase(MECHENICAL_CONDITION_S, (state, action: any) => {
      state.stepperInputs.MechenicalCondition = action.payload?.map((item) => {
        return ({ label: item?.name, value: item?.id })
      });
    });

    builder.addCase(USAGE_S, (state, action: any) => {
      state.stepperInputs.Usage = action.payload?.map((item) => {
        return ({ label: item?.name, value: item?.id })
      });
    });

    builder.addCase(ACCESSORY_TYPE_S, (state, action: any) => {
      state.accessoryType = action.payload;
      state.stepperInputs.Category = action.payload?.map((item) => {
        return ({ label: item?.name, value: item?.accessoryId })
      });
    });

    builder.addCase(ACCESSORY_SUBTYPE_S, (state, action: any) => {
      state.accessorySubType = action.payload;
    });
    builder.addCase(CAR_DETAILS_S, (state, action: any) => {
      state.searchResultCar = {
        ...state.searchResultCar,
        details: action.payload,
        totalPages: 0
      };
    });
    builder.addCase(GET_HOME_S, (state, action: any) => {
      state.homeListing = {
        usedCar: action.payload.usedCarFeatured,
        newCar: action.payload.newCarFeatured,
        autoPart: action.payload.featuredCarAccesories
      };
    });

    builder.addCase(GET_CMS_S, (state, action: any) => {
      state.cmsValue = action.payload.value
    });

    builder.addCase(GET_FAQ_S, (state, action: any) => {
      state.faq = action.payload

    });
    builder.addCase(COUNTRY_S, (state, action: any) => {
      state.countryOption = action.payload?.length ? action.payload.map((val) => ({ label: val?.name, value: val?.name })) : []
    });

    builder.addCase(COUNTRY_F, (state, action: any) => {
      state.countryOption = []

    });
  },
});

export default metaSlice.reducer;
