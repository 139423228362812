import { THEMES } from 'constants/common';
import { Toaster } from 'react-hot-toast'
import { light, dark } from 'style/theme';

const Toast = ({ theme }) => {

    return (
        <Toaster
            containerStyle={{
                top: 100,
                left: 20,
                bottom: 20,
                right: 20,
            }}
            // position="top-center"
            toastOptions={{
                duration: 3000,
                style: {
                    background: theme === THEMES.DARK ? light.colors.common.bgColor : dark.colors.common.bgColor,
                    color: theme === THEMES.DARK ? light.colors.common.black : dark.colors.common.white
                },
            }} />
    )
}

export default Toast;