// ENV CONSTANTS
export const { REACT_APP_API_BASE: API_BASE } = process.env;
export const { REACT_APP_NAME: APP_NAME } = process.env;
export const { REACT_APP_TRANSLATION_PATH: APP_TRANSLATION_FILE_PATH } = process.env;
export const { REACT_APP_FACEBOOK_APPID: FACEBOOK_APPID } = process.env;
export const { REACT_APP_GOOGLE_APPID: GOOGLE_APPID } = process.env;
export const { REACT_APP_APPLE_APPID: APPLE_APPID } = process.env;
export const { REACT_APP_SOCIAL_LOGIN_URL: SOCIAL_LOGIN_URL } = process.env;
export const { REACT_APP_GOOGLE_PLACE_API_KEY: GOOGLE_PLACE_KEY } = process.env;

// LOCAL STORAGE VARIABLEPHONE_NO_FORMAT
export const LS_USER: string = `user${APP_NAME}`
export const LS_AUTHORED: string = `authToken${APP_NAME}`
export const LS_REFRESH_TOKEN: string = `refreshToken${APP_NAME}`
export const LS_LANGUAGE: string = `i18nextLng`
export const LS_THEME: string = `theme`

//autoPart
export const LS_LASTACTIVE_STEP: string = `lastActiveStep`

export const PHONE_NO_FORMAT = "+91##########"; //+971 #########
export const EXCLUDE_FOOTER_ROUTES: string[] = ['/auth', '/post', '/cms', '/contact']
