import { THEMES } from "constants/common";
import HashLoader from "react-spinners/BounceLoader";
import { useAppSelector } from "services/store";
import { Wrapper } from "./style";

interface IProps {
  isFallback: boolean;
  loaderWithoutBg?: boolean
}

const Loading = (props: IProps) => {
  const { isFallback, loaderWithoutBg } = props
  const { isLoading, theme } = useAppSelector(state => state?.auth);

  return (
    <>
      {isLoading || isFallback ? <Wrapper className={loaderWithoutBg ? 'loaderWithoutBg' : 'loaderWithBg'}>
        <HashLoader
          color={theme === THEMES.DARK ? '#CACACA' : loaderWithoutBg ? 'black' : '#434343'}
          loading={true}
          className="hasLoader"
          size={window.innerHeight / 9}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </Wrapper> : ''}
    </>
  );
};

export default Loading;