export const size = {
    xxl: `1440px`,
    xl: `1439px`,
    lg: `1199px`,
    md: `991px`,
    sm: `767px`,
    xs: `575px`,
}
export const device = {
    xxl: `min-width: ${size.xxl}`,
    xl: `max-width: ${size.xl}`,
    lg: `max-width: ${size.lg}`,
    md: `max-width: ${size.md}`,
    sm: `max-width: ${size.sm}`,
    xs: `max-width: ${size.xs}`,
}
// export default {size, device}

