import BreadCrumbs from "components/common/BreadCrumbs";
import { Wrapper } from "./style";
import Card from "components/common/Card";
import { useForm } from "react-hook-form";
import {
  RenderNumberInput,
  RenderTextInput,
  CustomRenderSelectInput,
} from "components/common/FormField";
import { useTranslation } from "react-i18next";
import { PHONE_NO_FORMAT } from "../../constants";
import Button from "components/common/Button";
import ReactImageUploading from "react-images-uploading";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { editUserProfile, getUserProfile } from "services/UserDataSlice/action";
import { useAppSelector } from "services/store";
import { authFail } from "services/AuthSlice";
import { ApiErrorResponse, ApiResponse } from "Types/Common/Common";
import toast from "react-hot-toast";
import { GENDER_OPTION } from "constants/common";
import { toAbsoluteUrl } from "utils";
import { getCountryList } from "services/MetaSlice/action";
import { COUNTRY_F } from "constants/redecuerType";
import Pencil from "assets/svg/pencil";

const Profile = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm();

  const dispatch = useDispatch();
  const {
    userData: { userProfile },
    meta: { countryOption },
  } = useAppSelector((state) => state);

  const [images, setImages] = useState<any[]>([]);

  useEffect(() => {
    dispatch(getCountryList()).finally(() => dispatch(getUserProfile()));

    return () => {
      dispatch({ type: COUNTRY_F, payload: [] });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValue("name", userProfile?.name);
    setValue("gender", userProfile?.gender);
    setValue("email", userProfile?.email);
    setValue("phoneNumber", userProfile?.phoneNumber);
    setValue("location", userProfile?.location);
    setValue("nationality", userProfile?.nationality);
    if (userProfile?.userImageUrl) {
      setImages([{ data_url: userProfile?.userImageUrl }]);
    }
  }, [setValue, userProfile]);

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  };

  const handleLogout = () => {
    dispatch(authFail({}));
    window.location.reload();
  };

  const onSubmit = (value) => {

    const formData = new FormData();
    if (images.length > 0) {
      formData.append('profileImage', images[0]?.file)
    }
    formData.append('name', value?.name)
    formData.append('gender', value?.gender)
    formData.append('email', value?.email)
    formData.append('phoneNumber', value?.phoneNumber)
    formData.append('location', value?.location)
    formData.append('nationality', value?.nationality)

    dispatch(editUserProfile(formData))
      .then((res: ApiResponse) => {
        dispatch(getUserProfile());
        reset();
        toast.success(t("messages.profile_update"));
      })
      .catch((err: ApiErrorResponse) => toast.error(err.ResponseException));
  };

  const breadcrumb = [
    {
      name: t("my_profile"),
      link: "/",
      isActive: true,
    },
  ];

  return (
    <>
      <Wrapper className="profileWrap">
        <div className="container">
          <BreadCrumbs list={breadcrumb} />
          <div className="profileWrap">
            <div className="profileImage">
              <ReactImageUploading
                value={images}
                onChange={onChange}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  dragProps,
                }) => (
                  <div className="upload__image-wrapper">
                    <button onClick={onImageUpload} {...dragProps}>
                      <span>
                        <Pencil />
                      </span>
                    </button>

                    {imageList?.map((image, index) => (
                      <div key={index} className="image-item">
                        <img
                          src={image.data_url}
                          alt="profile_image"
                          width="100"
                        />
                      </div>
                    ))}

                    {images.length === 0 && (
                      <div className="image-item">
                        <img
                          src={toAbsoluteUrl(`/images/profile.png`)}
                          alt="profile_image"
                          width="90"
                        />
                      </div>
                    )}
                  </div>
                )}
              </ReactImageUploading>
              <div className="profileName">
                <h3>{userProfile?.name || "-"}</h3>
              </div>
              <div className="logoutBtnWrap">
                <Button variant="primary" type="button" onClick={handleLogout}>
                  {t("logout")}
                </Button>
              </div>
            </div>
            <div className="profileDetiails">
              <Card className="profileCard">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <ul>
                    <li>
                      <RenderTextInput
                        type="text"
                        placeholder="none"
                        labelName={t("full_name")}
                        register={register("name", {
                          required: t("required") || "",
                        })}
                        errorMessage={
                          errors?.name ? errors?.name?.message : ""
                        }
                      />
                    </li>
                    <li>
                      <CustomRenderSelectInput
                        containerClasses="selectFloating"
                        labelName={t("profiles.gender")}
                        placeholder="none"
                        options={GENDER_OPTION}
                        register={register("gender", {
                          required: t("required") || "",
                        })}
                        errorMessage={
                          errors?.gender ? errors?.gender?.message : ""
                        }
                        control={control}
                      />
                    </li>
                    <li>
                      <RenderTextInput
                        type="email"
                        placeholder="none"
                        disabled={userProfile?.email ? true : false}
                        labelName={t("email")}
                        register={register("email", {
                          required: t("required") || "",
                        })}
                        errorMessage={
                          errors?.email ? errors?.email?.message : ""
                        }
                      />
                    </li>
                    <li>
                      <RenderNumberInput
                        formatField={PHONE_NO_FORMAT}
                        setValue={setValue}
                        placeholder="none"
                        labelName={t("phone_number")}
                        register={register("phoneNumber", {
                          required: t("required") || "",
                          validate: (val: string): any => {
                            let trimmedData = val?.trim();
                            if (
                              trimmedData?.length > 13 ||
                              trimmedData?.length < 13
                            ) {
                              return t("validation_msg.phone_number");
                            }
                          },
                        })}
                        errorMessage={
                          errors?.phoneNumber
                            ? errors?.phoneNumber?.message
                            : ""
                        }
                        control={control}
                      />
                    </li>
                    <li>
                      <CustomRenderSelectInput
                        containerClasses="selectFloating"
                        labelName={t("profiles.nationality")}
                        placeholder="none"
                        options={countryOption}
                        register={register("nationality", {
                          required: t("required") || "",
                        })}
                        errorMessage={
                          errors?.nationality
                            ? errors?.nationality?.message
                            : ""
                        }
                        control={control}
                      />
                    </li>
                    <li>
                      <RenderTextInput
                        type="text"
                        placeholder="none"
                        labelName={t("location")}
                        register={register("location", {
                          required: t("required") || "",
                        })}
                        errorMessage={
                          errors?.location ? errors?.location?.message : ""
                        }
                      />
                    </li>
                  </ul>
                  <Button
                    variant="primary"
                    type="submit"
                    // disabled={!isDirty}
                    buttonClass="updateButton"
                  >
                    {t("update")}
                  </Button>
                </form>
              </Card>
              <Card className="profileCount">
                <ul>
                  <li>
                    <h2>{t("profiles.my_ads")}</h2>
                    <h4>{userProfile?.counts?.ads || 0}</h4>
                    <h3>{t("profiles.ads_viewed")}</h3>
                  </li>
                  <li>
                    <h2>{t("profiles.my_favorites")}</h2>
                    <h4>{userProfile?.counts?.favorites || 0}</h4>
                    <h3>{t("profiles.ads_saved")}</h3>
                  </li>{" "}
                  <li>
                    <h2>{t("profiles.my_searches")}</h2>
                    <h4>{userProfile?.counts?.searches || 0}</h4>
                    <h3>{t("profiles.saved_search")}</h3>
                  </li>
                </ul>
              </Card>
            </div>
          </div>
        </div>
      </Wrapper >
    </>
  );
};

export default Profile;
