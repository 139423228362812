import Select from "react-select";
import { PatternFormat } from "react-number-format";
import {
  IRenderInputProps,
  IRenderInputWithoutFormProps,
  IRenderNumberInputProps,
  IRenderSelectInputProps,
} from "Types/Common/FormField";
import { Wrapper } from "./style";
import EyeLock from "assets/svg/eyeLock";
import Eye from "assets/svg/eye";
import { useState } from "react";
import { Controller } from "react-hook-form";
import Autocomplete from "react-google-autocomplete";
import { GOOGLE_PLACE_KEY } from "../../../constants";

// Text Field
export const RenderTextInput = ({
  register,
  type,
  children,
  placeholder,
  containerClasses,
  inputClasses,
  labelClasses,
  labelName,
  disabled,
  errorClasses,
  errorMessage,
}: IRenderInputProps) => {
  return <>
    <Wrapper className={`formGroup ${containerClasses}`}>
      <input
        {...register}
        className={`formInput ${inputClasses}`}
        disabled={disabled}
        type={type}
        placeholder={placeholder}
      />
      <label
        htmlFor={labelName}
        className={`formLabel ${labelClasses}`}
        data-content={labelName}
      >
        <span className="hidden--visually">{labelName}</span>
      </label>
      <span className="childrenClass">{children}</span>
    </Wrapper>
    <div className={`inputError ${errorClasses}`}>
      {errorMessage && (
        <span className="textDanger textRight">{errorMessage}</span>
      )}
    </div>
  </>
}
export const RenderTextInputWithoutForm = ({
  type,
  children,
  placeholder,
  containerClasses,
  inputClasses,
  labelClasses,
  labelName,
  disabled,
  onChange,
  value
}: IRenderInputWithoutFormProps) => {
  return <>
    <Wrapper className={`formGroup ${containerClasses}`}>
      <input
        className={`formInput ${inputClasses}`}
        disabled={disabled}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      <label
        htmlFor={labelName}
        className={`formLabel ${labelClasses}`}
        data-content={labelName}
      >
        <span className="hidden--visually">{labelName}</span>
      </label>
      <span className="childrenClass">{children}</span>
    </Wrapper>
  </>
}
// Text Area Field
export const RenderTextAreaInput = ({
  register,
  placeholder,
  containerClasses,
  inputClasses,
  labelClasses,
  labelName,
  errorClasses,
  errorMessage,
}: IRenderInputProps) => (
  <>
    <Wrapper className={`formGroup ${containerClasses}`}>
      <textarea
        {...register}
        className={`formInput ${inputClasses}`}
        placeholder={placeholder}
      />
      <label
        htmlFor={labelName}
        className={`formLabel ${labelClasses}`}
        data-content={labelName}
      >
        <span className="hidden--visually">{labelName}</span>
      </label>
    </Wrapper>
    <div className={`inputError ${errorClasses}`}>
      {errorMessage && (
        <span className="textDanger textRight">{errorMessage}</span>
      )}
    </div>
  </>
);

// Number Field
export const RenderNumberInput = ({
  register,
  placeholder,
  formatField = "####",
  containerClasses,
  inputClasses,
  labelClasses,
  labelName,
  disabled,
  errorClasses,
  errorMessage,
  control,
  setValue
}: IRenderNumberInputProps) => {
  return (
    <>
      <div className="numberFeild">
        <Controller
          name={register.name}
          control={control}
          render={({ field }) =>
            <Wrapper className={`formGroup ${containerClasses}`}>
              <PatternFormat
                {...register}
                name={register.name}
                value={field.value}
                onChange={(e) => { setValue(register.name, e.target.value); field.onChange(e) }}
                format={formatField}
                placeholder={placeholder}
                disabled={disabled}
                className={`formInput ${inputClasses}`}
              />
              <label
                htmlFor={labelName}
                className={`formLabel ${labelClasses}`}
                data-content={labelName}
              >
                <span className="hidden--visually">{labelName}</span>
              </label>
            </Wrapper>
          }
        />
        <div className={`inputError ${errorClasses}`}>
          {errorMessage && (
            <span className="textDanger textRight">{errorMessage}</span>
          )}
        </div>
      </div>
    </>
  );
};

// React Select Field
export const RenderSelectInput = ({
  register,
  placeholder,
  containerClasses,
  labelClasses,
  options = [],
  labelName,
  disabled,
  errorClasses,
  errorMessage,
  watch,
  onChange,
  valueSelect
}: any) => {
  return <>
    <Wrapper className={`formGroup ${containerClasses}`}>
      <Select
        {...register}
        classNamePrefix='demoClass'
        isSearchable={false}
        className='demoMain'
        options={options}
        placeholder={placeholder}
        onChange={onChange}
      />
      <label
        htmlFor={labelName}
        className={`${!valueSelect ? `formLabel ${labelClasses}` : `floatingFormLabel ${labelClasses}`}  `}
        data-content={labelName}
      >
        <span className="hidden--visually">{labelName}</span>
      </label>
    </Wrapper>
    <div className={`inputError ${errorClasses}`}>
      {errorMessage && (
        <span className="textDanger textRight">{errorMessage}</span>
      )}
    </div>
  </>
}

// password
export const RenderPassword = ({
  register,
  type,
  children,
  placeholder,
  containerClasses,
  inputClasses,
  labelClasses,
  labelName,
  disabled,
  errorClasses,
  errorMessage,
}: IRenderInputProps) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  return (
    <>
      <Wrapper className={`formGroup ${containerClasses}`}>
        <input
          {...register}
          className={`formInput ${inputClasses}`}
          disabled={disabled}
          type={passwordShown ? "text" : "password"}
          placeholder="none"
        />
        <span className="paswordIconWrap" onClick={togglePasswordVisiblity}>
          {passwordShown ? <EyeLock /> : <Eye />}
        </span>
        <label
          htmlFor={labelName}
          className={`formLabel ${labelClasses}`}
          data-content={labelName}
        >
          <span className="hidden--visually">{labelName}</span>
        </label>
      </Wrapper>
      <div className={`inputError ${errorClasses}`}>
        {errorMessage && (
          <span className="textDanger textRight">{errorMessage}</span>
        )}
      </div>
    </>
  );
};

// custom select 
export const CustomRenderSelectInput = ({
  register,
  placeholder,
  containerClasses,
  labelClasses,
  options = [],
  labelName,
  disabled,
  errorClasses,
  errorMessage,
  control
}: IRenderSelectInputProps) => {
  return <>
    <Controller
      name={register.name}
      control={control}
      render={({ field }) =>
        <Wrapper className={`formGroup ${containerClasses}`}>
          <select {...register} disabled={disabled || false} onChange={field.onChange} placeholder="Select asd" className='demoMain'>
            <option style={{ display: 'none' }} value={''}>{ }</option>
            {options?.map((val) => {
              return <option value={val.value}>{val?.label}</option>
            })}
          </select>
          <label
            htmlFor={labelName}
            className={`${field.value ? `floatingFormLabel ${labelClasses} ` : `formLabel ${labelClasses}`}  `}
            data-content={labelName}
          >
            <span className="hidden--visually">{labelName}</span>
          </label>
        </Wrapper>
      }
    />
    <div className={`inputError ${errorClasses}`}>
      {errorMessage && (
        <span className="textDanger textRight">{errorMessage}</span>
      )}
    </div>
  </>
}

export function LocationSearchInput(
  {
    register,
    placeholder,
    containerClasses,
    labelClasses,
    labelName,
    errorMessage,
    children,
    control,
    setValue,
    trigger
  }: any) {
  return (<>

    <Controller
      name={register.name}
      control={control}
      render={({ field }) =>
        <Wrapper className={`formGroup ${containerClasses}`}>
          <Autocomplete
            {...register}
            className={`formInput`}
            onChange={(e) => { field.onChange(e); trigger && trigger(register.name); }}
            value={field.value}
            placeholder={placeholder}
            apiKey={GOOGLE_PLACE_KEY}
            options={{
              types: [],
              componentRestrictions: { country: "ae" },
              fields: ['ALL']
            }}
            onPlaceSelected={(place) => {
              setValue(register.name, place?.formatted_address || '')
              if (place.geometry) {
                setValue('latitude', place.geometry?.location?.lat() || '')
                setValue('longitude', place.geometry?.location?.lng() || '')
              }
              trigger && trigger(register.name);
            }}
          />
          <label
            htmlFor={labelName}
            className={`formLabel ${labelClasses}`}
            data-content={labelName}
          >
            <span className="hidden--visually">{labelName}</span>
          </label>
          <span className="childrenClass">{children}</span>
        </Wrapper>
      } />
    <div className={`inputError`}>
      {errorMessage && (
        <span className="textDanger textRight">{errorMessage}</span>
      )}
    </div>
  </>)
}
