import { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ROUTES } from "constants/routes";
import Profile from "./Profile";
import ComingSoon from "components/ComingSoon";

// Auth Routes
const SignIn = lazy(() => import("./Authentication/SignIn"));
const SignUp = lazy(() => import("./Authentication/Signup"));
const AuthGuard = lazy(() => import("components/AuthGuard"));
const SignInOption = lazy(() => import("./Authentication/AuthOption"));
const ResetPassword = lazy(() => import("./Authentication/ResetPassword"));
const ChangePassword = lazy(() => import("./Authentication/ChangePassword"));
const ForgotPassword = lazy(() => import("./Authentication/ForgotPassword"));
const EmailVerification = lazy(() => import("./Authentication/EmailVerification"));
const MobilePayment = lazy(() => import("./MobilePayment"));
// Home Routes
const Home = lazy(() => import("./Home"));
const SaveSerach = lazy(() => import("./SavedSearch"));
const Layout = lazy(() => import("../components/layout"));
const SearchResult = lazy(() => import("./SearchResult"));
const PostDetail = lazy(() => import("./CarListDetails"));
const AutoPartDetails = lazy(() => import("./AutoPartDetails"));
const AutoPartsSearchResult = lazy(() => import("./AutoPartsSerachResult"));
const Favourites = lazy(() => import("./Favourites"));

const CarType = lazy(() => import("./PostAds/CarType"));
const Checkout = lazy(() => import("./PostAds/Checkout"));
const PartType = lazy(() => import("./PostAds/PartType"));
const CarNumber = lazy(() => import("./PostAds/CarNumber"));
const ManageAds = lazy(() => import("./PostAds/ManageAds"));
const MergeStep = lazy(() => import("./PostAds/CarDetail"));
const PartDetail = lazy(() => import("./PostAds/PartDetail"));
const PartSubType = lazy(() => import("./PostAds/PartSubType"));
const ChassisNumber = lazy(() => import("./PostAds/ChasisNumber"));
const SelectProduct = lazy(() => import("./PostAds/SelectProduct"));
const EditPost = lazy(() => import("./PostAds/EditPostDetails"));
const Contact = lazy(() => import("./Contact"));
const Faq = lazy(() => import("./Cms/FAQ"));
const Cms = lazy(() => import("./Cms/TermsPrivacy"));

const Routing = () => {
  return (
    <Routes>
      <Route path={ROUTES.resetPassword} element={<ResetPassword />} />
      <Route path={ROUTES.mobilePayment} element={<MobilePayment />} />
      <Route
        path="/"
        element={<Layout />}>
        {/* Public Route */}
        {/* Auth */}
        <Route path={ROUTES.signInOption} element={<AuthGuard isPublic={true}><SignInOption /></AuthGuard>} />
        <Route path={ROUTES.signIn} element={<AuthGuard isPublic={true}><SignIn /></AuthGuard>} />
        <Route path={ROUTES.signUp} element={<AuthGuard isPublic={true}><SignUp /></AuthGuard>} />
        <Route path={ROUTES.emailVerification} element={<EmailVerification />} />
        {/* <Route path={ROUTES.mobileVerification} element={<AuthGuard isPublic={true}><MobileVerification /></AuthGuard>} /> */}
        <Route path={ROUTES.changePassword} element={<AuthGuard isPublic={false}><ChangePassword /></AuthGuard>} />
        <Route path={ROUTES.forgotPassword} element={<ForgotPassword />} />

        {/* Home */}
        <Route index path={ROUTES.default} element={<Home />} />
        <Route path={ROUTES.searchResult + `/:postType`} element={<SearchResult />} />
        <Route path={ROUTES.autoPartsSearchResult + `/:postType`} element={<AutoPartsSearchResult />} />
        <Route path={ROUTES.postDetail + `/:postId`} element={<PostDetail />} />
        <Route path={ROUTES.autoPartDetail} element={<AutoPartDetails />} />

        {/* User Routes */}
        <Route path={ROUTES.savedSearch} element={<AuthGuard isPublic={false}> <SaveSerach /> </AuthGuard>} />
        <Route path={ROUTES.favourite + '/:postType'} element={<AuthGuard isPublic={false}><Favourites /></AuthGuard>} />
        <Route path={ROUTES.profile} element={<AuthGuard isPublic={false}> <Profile /> </AuthGuard>} />

        {/* Post */}
        <Route path={ROUTES.selectProducts} element={<AuthGuard isPublic={false}><SelectProduct /> </AuthGuard>} />
        <Route path={ROUTES.productType + '/:product'} element={<AuthGuard isPublic={false}><CarType /></AuthGuard>} />
        <Route path={ROUTES.carNumber + '/:product'} element={<AuthGuard isPublic={false}><CarNumber /></AuthGuard>} />
        <Route path={ROUTES.chassisNumber + '/:draftId/:activeCarIndex'} element={<AuthGuard isPublic={false}><ChassisNumber /></AuthGuard>} />
        <Route path={ROUTES.carDetail + '/:draftId/:activeCarIndex'} element={<AuthGuard isPublic={false}><MergeStep /></AuthGuard>} />
        <Route path={ROUTES.payment + '/:draftId/:activeCarIndex'} element={<AuthGuard isPublic={false}><Checkout /></AuthGuard>} />
        <Route path={ROUTES.paymentAccessory + '/:accessoryId/:accessorySubId/:postId'} element={<AuthGuard isPublic={false}><Checkout /></AuthGuard>} />
        <Route path={ROUTES.accessoryType} element={<AuthGuard isPublic={false}><PartType /></AuthGuard>} />
        <Route path={ROUTES.accessorySubType + '/:accessoryId'} element={<AuthGuard isPublic={false}><PartSubType /></AuthGuard>} />
        <Route path={ROUTES.partDetail + '/:accessoryId/:accessorySubId'} element={<AuthGuard isPublic={false}><PartDetail /></AuthGuard>} />
        <Route path={ROUTES.manageAds + '/:postId'} element={<AuthGuard isPublic={false}><ManageAds /></AuthGuard>} />
        <Route path={ROUTES.EditPost + '/:postId/:postType'} element={<AuthGuard isPublic={false}><EditPost /></AuthGuard>} />

        {/* Contact */}
        <Route path={ROUTES.contact} element={<Contact />} />

        {/* Cms */}
        <Route path={ROUTES.faq} element={<Faq />} />
        <Route path={ROUTES.cms + `/:type`} element={<Cms />} />
        <Route path={ROUTES.comingSoon} element={<ComingSoon />} />
      </Route>
      <Route path="*" element={<Navigate replace to={ROUTES.default} />} />
    </Routes>
  );
};

export default Routing;
