import axios from "axios";
import '../i18n/config'
import { BrowserRouter } from "react-router-dom";
import { store, useAppSelector } from "../services/store";
import Routes from "./routes";
import Loader from "../components/common/loader";
import { setupAxios, setupLangTheme } from "../utils";
import { ThemeProvider } from 'styled-components';
import { light, dark } from '../style/theme';
import GlobalStyle from "style/global";
import { THEMES } from "constants/common";

import 'react-loading-skeleton/dist/skeleton.css'
import Toast from "components/common/Toaster";

const { PUBLIC_URL } = process.env;

setupAxios(axios, store);
setupLangTheme(store);

const AppContainer = () => {

  const { theme } = useAppSelector(state => state.auth)

  return <ThemeProvider theme={theme === THEMES.DARK ? dark : light} >
    <Loader isFallback={false} loaderWithoutBg={false} />
    <Toast theme={theme} />
    <BrowserRouter basename={PUBLIC_URL}>
      <GlobalStyle />
      <Routes />
    </BrowserRouter>
  </ThemeProvider>
};

export default AppContainer;
