// TYPES FOR REDUCER
export const LOGIN_S: string = `LOGIN_S`;
export const LOGIN_F: string = `LOGIN_F`;

export const SIGNUP_S: string = `SIGNUP_S`;

export const FORGOT_PASS_S: string = `FORGOT_PASS_S`;
export const FORGOT_PASS_F: string = `FORGOT_PASS_F`;

export const SOCIAL_LOGIN_REG_S: string = `SOCIAL_LOGIN_REG_S`;
export const SOCIAL_LOGIN_REG_F: string = `SOCIAL_LOGIN_REG_F`;

export const RESET_PASS_S: string = `RESET_PASS_S`;
export const RESET_PASS_F: string = `RESET_PASS_F`;

export const USER_LIST_S: string = `USER_LIST_S`;
export const USER_LIST_F: string = `USER_LIST_F`;

export const USER_INFO_S: string = `USER_INFO_S`;
export const USER_INFO_F: string = `USER_INFO_F`;

export const REFRESH_TOKEN_S: string = `REFRESH_TOKEN_S`;
export const REFRESH_TOKEN_F: string = `REFRESH_TOKEN_F`;

export const COUNTRY_S: string = `COUNTRY_S`;
export const COUNTRY_F: string = `COUNTRY_F`;

// POST 
export const DRAFT_DETAIL_S: string = `GET_DRAFT_DETAIL_S`;
export const DRAFT_DETAIL_F: string = `GET_DRAFT_DETAIL_F`;

export const DRAFT_SUMMARY_S: string = `GET_DRAFT_SUMMARY_S`;
export const DRAFT_SUMMARY_F: string = `GET_DRAFT_SUMMARY_F`;

export const DRAFT_META_DETAIL_S: string = `DRAFT_META_DETAIL_S`;
export const DRAFT_META_DETAIL_F: string = `DRAFT_META_DETAIL_F`;

export const DRAFT_PACKAGE_S: string = `DRAFT_PACKAGE_S`;
export const DRAFT_PACKAGE_F: string = `DRAFT_PACKAGE_F`;

export const CAR_DETAILS_S: string = `CAR_DETAILS_S`;
export const CAR_DETAILS_F: string = `CAR_DETAILS_F`;

// POST DROPDOWN 

export const BODY_CONDITION_S: string = `GET_BODY_CONDITION_S`;
export const BODY_CONDITION_F: string = `GET_BODY_CONDITION_F`;

export const BODY_TYPE_S: string = `GET_BODY_TYPE_S`;
export const BODY_TYPE_F: string = `GET_BODY_TYPE_F`;

export const CAR_COLOR_S: string = `GET_CAR_COLOR_S`;
export const CAR_COLOR_F: string = `GET_CAR_COLOR_F`;

export const CAR_MODEL_S: string = `GET_CAR_MODEL_S`;
export const CAR_MODEL_F: string = `GET_CAR_MODEL_F`;

export const CITY_S: string = `GET_CITY_S`;
export const CITY_F: string = `GET_CITY_F`;

export const HORSE_POWER_S: string = `GET_HORSE_POWER_S`;
export const HORSE_POWER_F: string = `GET_HORSE_POWER_F`;

export const INTERIOR_COLOR_S: string = `GET_INTERIOR_COLOR_S`;
export const INTERIOR_COLOR_F: string = `GET_INTERIOR_COLOR_F`;

export const MAKE_S: string = `GET_MAKE_S`;
export const MAKE_F: string = `GET_MAKE_F`;

export const MECHENICAL_CONDITION_S: string = `GET_MECHENICAL_CONDITION_S`;
export const MECHENICAL_CONDITION_F: string = `GET_MECHENICAL_CONDITION_F`;

export const USAGE_S: string = `GET_USAGE_S`;
export const USAGE_F: string = `GET_USAGE_F`;

// Accessory
export const ACCESSORY_TYPE_S: string = `GET_ACCESSORY_TYPE_S`;
export const ACCESSORY_TYPE_F: string = `GET_ACCESSORY_TYPE_F`;

export const ACCESSORY_SUBTYPE_S: string = `GET_ACCESSORY_SUBTYPE_S`;
export const ACCESSORY_SUBTYPE_F: string = `GET_ACCESSORY_SUBTYPE_F`;

export const ACCESSORY_REVIEW_S: string = `GET_ACCESSORY_REVIEW_S`;
export const ACCESSORY_REVIEW_F: string = `GET_ACCESSORY_REVIEW_F`;

export const GET_ACCESSORY_S: string = `GET_ACCESSORY_S`;
export const GET_ACCESSORY_F: string = `GET_ACCESSORY_F`;

export const ACCESSORY_PACKAGE_S: string = `GET_ACCESSORY_PACKAGE_S`;
export const ACCESSORY_PACKAGE_F: string = `GET_ACCESSORY_PACKAGE_F`;

// Search Result

export const GET_SEARCH_RESULT_S: string = `GET_GET_SEARCH_RESULT_S`;
export const GET_SEARCH_RESULT_F: string = `GET_GET_SEARCH_RESULT_F`;

// Home

export const GET_HOME_S: string = `GET_HOME_S`;
export const GET_HOME_F: string = `GET_HOME_F`;

// save search

export const GET_SAVED_SEARCH_S: string = `GET_SAVED_SEARCH_S`;
export const GET_SAVED_SEARCH_F: string = `GET_SAVED_SEARCH_F`;

// Profile
export const GET_PROFILE_S: string = `GET_PROFILE_S`;
export const GET_PROFILE_F: string = `GET_PROFILE_F`;

// Favourite Post
export const GET_LIST_FAVOURITE_S: string = `GET_LIST_FAVOURITE_S`;
export const GET_LIST_FAVOURITE_F: string = `GET_LIST_FAVOURITE_F`;

// Mange Ads
export const GET_LIST_MANAGE_ADS_S: string = `GET_LIST_MANAGE_ADS_S`;
export const GET_LIST_MANAGE_ADS_F: string = `GET_LIST_MANAGE_ADS_F`;

// Car Post Detail
export const GET_CAR_POST_DETAIL_S: string = `GET_CAR_POST_DETAIL_S`;
export const GET_CAR_POST_DETAIL_F: string = `GET_CAR_POST_DETAIL_F`;

// Auto part Post Detail
export const GET_PART_POST_DETAIL_S: string = `GET_PART_POST_DETAIL_S`;
export const GET_PART_POST_DETAIL_F: string = `GET_PART_POST_DETAIL_F`;

// Cms
export const GET_CMS_S: string = `GET_CMS_S`;
export const GET_CMS_F: string = `GET_CMS_F`;

// FAQ
export const GET_FAQ_S: string = `GET_FAQ_S`;
export const GET_FAQ_F: string = `GET_FAQ_F`;
