import { API_DELETE_SAVE_SEARCH, API_LIST_POST_FAV, API_GET_PROFILE, API_MANAGE_ADS_LIST, API_REMOVE_MANAGE_ADS, API_GET_CAR_POST_DETAIL, API_EDIT_CAR_POST_DETAIL, API_PART_CAR_POST_DETAIL, API_ADD_POST_FAV, API_REMOVE_POST_FAV, API_ADD_SAVE_SEARCH, API_GET_SAVED_SEARCH, API_RENAME_SAVE_SEARCH, API_PART_CAR_POST_REVIEW, API_CONTACT, API_CHANGE_POST_STATUS, API_REMOVE_POST_FAV_LIST } from './../../constants/api';
import { GET_LIST_FAVOURITE_F, GET_LIST_FAVOURITE_S, GET_SAVED_SEARCH_F, GET_SAVED_SEARCH_S, GET_PROFILE_F, GET_PROFILE_S, GET_LIST_MANAGE_ADS_S, GET_LIST_MANAGE_ADS_F, GET_CAR_POST_DETAIL_S, GET_CAR_POST_DETAIL_F, GET_PART_POST_DETAIL_S, GET_PART_POST_DETAIL_F } from 'constants/redecuerType';

export const saveSearchAction: any = (data) => ({
    type: "API",
    payload: {
        url: API_ADD_SAVE_SEARCH,
        method: "POST",
        hideLoader: false,
        data
    },
});

export const renameSaveSearchAction: any = (data) => ({
    type: "API",
    payload: {
        url: API_RENAME_SAVE_SEARCH + `?id=${data?.id}&name=${data?.name}&isEmailNotificationEnable=${data?.isEmailNotificationEnable}`,
        method: "PUT",
        hideLoader: false,
        data
    },
});

export const deleteSaveSearchAction: any = (data) => ({
    type: "API",
    payload: {
        url: API_DELETE_SAVE_SEARCH + `/${data?.id}`,
        method: "DELETE",
        hideLoader: false,
        data
    },
});


export const getSavedSearch: any = (data) => ({
    type: "API",
    payload: {
        url: API_GET_SAVED_SEARCH + `?PageNumber=${data?.PageNumber || 1}&PageSize=${data?.PageSize || 3}&Sorting=${data?.sorting || ''}`,
        method: "POST",
        hideLoader: true,
        data,
        success: (data) => ({
            type: GET_SAVED_SEARCH_S,
            payload: data,
        }),
        error: (data) => ({
            type: GET_SAVED_SEARCH_F,
            payload: [],
        }),
    },
});

export const addPostToFavorite: any = (data) => ({
    type: "API",
    payload: {
        url: API_ADD_POST_FAV + `?postDetailId=${data?.postDetailId || 1}&postType=${data?.postType}`,
        method: "POST",
        hideLoader: true,
        data
    },
});

export const removePostFromFavorite: any = (data) => ({
    type: "API",
    payload: {
        url: API_REMOVE_POST_FAV + `/${data?.postDetailId}`,
        method: "DELETE",
        hideLoader: true,
        data
    },
});

export const getUserProfile: any = (data) => ({
    type: "API",
    payload: {
        url: API_GET_PROFILE,
        method: "GET",
        hideLoader: false,
        data,
        success: (data) => ({
            type: GET_PROFILE_S,
            payload: data,
        }),
        error: (data) => ({
            type: GET_PROFILE_F,
            payload: {},
        }),
    },
});

export const editUserProfile: any = (data) => ({
    type: "API",
    payload: {
        url: API_GET_PROFILE,
        method: "PUT",
        hideLoader: false,
        data: data,
    },
});

export const removePostListFromFavorite: any = (data) => ({
    type: "API",
    payload: {
        url: API_REMOVE_POST_FAV_LIST + data,
        method: "DELETE",
        hideLoader: true,
        data
    },
});

export const PostListOfFavorite: any = (data) => ({
    type: "API",
    payload: {
        url: API_LIST_POST_FAV + data,
        method: "POST",
        hideLoader: true,
        success: (data) => ({
            type: GET_LIST_FAVOURITE_S,
            payload: data,
        }),
        error: (data) => ({
            type: GET_LIST_FAVOURITE_F,
            payload: [],
        }),
    },
});

export const listManageAdsAction: any = (id, args) => ({
    type: "API",
    payload: {
        url: API_MANAGE_ADS_LIST + `/${id}${args}`,
        method: "GET",
        hideLoader: true,
        success: (data) => ({
            type: GET_LIST_MANAGE_ADS_S,
            payload: data,
        }),
        error: (data) => ({
            type: GET_LIST_MANAGE_ADS_F,
            payload: [],
        }),
    },
});

export const removeMangeAds: any = (id) => ({
    type: "API",
    payload: {
        url: API_REMOVE_MANAGE_ADS + `/${id}`,
        method: "DELETE",
        hideLoader: true,
    },
});

export const addContactAction: any = (data) => ({
    type: "API",
    payload: {
        url: API_CONTACT,
        method: "POST",
        hideLoader: true,
        data: data
    },
});


// Car Post Detail
export const getCarPostDetail: any = (id) => ({
    type: "API",
    payload: {
        url: API_GET_CAR_POST_DETAIL + `/${id}`,
        method: "GET",
        hideLoader: false,
        success: (data) => ({
            type: GET_CAR_POST_DETAIL_S,
            payload: data,
        }),
        error: (data) => ({
            type: GET_CAR_POST_DETAIL_F,
            payload: {},
        }),
    },
});

export const editPostDetail: any = (data) => ({
    type: "API",
    payload: {
        url: API_EDIT_CAR_POST_DETAIL,
        method: "PUT",
        data: data,
        hideLoader: false,
    },
});

// Part Post Detail
export const getPartPostDetail: any = (id) => ({
    type: "API",
    payload: {
        url: API_PART_CAR_POST_DETAIL + `/${id}`,
        method: "GET",
        hideLoader: false,
        success: (data) => ({
            type: GET_PART_POST_DETAIL_S,
            payload: data,
        }),
        error: (data) => ({
            type: GET_PART_POST_DETAIL_F,
            payload: {},
        }),
    },
});

export const editPartPostDetail: any = (data) => ({
    type: "API",
    payload: {
        url: API_EDIT_CAR_POST_DETAIL,
        method: "PUT",
        data: data,
        hideLoader: false,
    },
});

export const getPartPostReview: any = (id) => ({
    type: "API",
    payload: {
        url: API_PART_CAR_POST_REVIEW + `/${id}`,
        method: "GET",
        hideLoader: false,
        success: (data) => ({
            type: GET_PART_POST_DETAIL_S,
            payload: data,
        }),
        error: (data) => ({
            type: GET_PART_POST_DETAIL_F,
            payload: {},
        }),
    },
});

export const updatePostStatus: any = (data) => ({
    type: "API",
    payload: {
        url: API_CHANGE_POST_STATUS + `/${data.id}`,
        method: "PUT",
        data: data,
        hideLoader: false,
    },
});