import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { APP_TRANSLATION_FILE_PATH } from '../constants';
console.log('APP_TRANSLATION_FILE_PATH: ', APP_TRANSLATION_FILE_PATH);

export const i18n = i18next
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(Backend)
    .init(
        {
            supportedLngs: ['en', 'ar'],
            fallbackLng: 'en',
            lng: localStorage.getItem('i18nextLng') || 'en',
            detection: {
                order: ['localStorage'],
            },
            debug: false,
            backend: {
                loadPath: `/locales/{{lng}}/translation.json`,
                allowMultiLoading: false,
                crossDomain: true,
            },
            react: {
                useSuspense: false,
            }
        },
    );
