const Pencil = () => {
  return (
    <>
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1125_12262)">
          <path
            d="M6.17554 1.67969L0.673399 7.18223C0.645718 7.20999 0.62573 7.24504 0.616212 7.28272L0.00635232 9.73057C-0.0118903 9.80425 0.00976291 9.88261 0.0635391 9.93639C0.104228 9.97708 0.15967 9.99961 0.216381 9.99961C0.233751 9.99961 0.251518 9.99746 0.268809 9.9931L2.71666 9.38316C2.75481 9.37364 2.78947 9.35374 2.81715 9.32605L8.31977 3.82391L6.17554 1.67969Z"
            fill="white"
          />
          <path
            d="M9.68346 0.929106L9.07098 0.316629C8.66163 -0.0927204 7.94818 -0.0923238 7.53931 0.316629L6.78906 1.06688L8.93321 3.21102L9.68346 2.46077C9.88793 2.25638 10.0006 1.98432 10.0006 1.69498C10.0006 1.40564 9.88793 1.13358 9.68346 0.929106Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_1125_12262">
            <rect width="10" height="10" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default Pencil;
