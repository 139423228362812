import { createSlice } from "@reduxjs/toolkit";
import { ICarPostDetail, IFavouritePost, IManageAds, IPartDetail, ISavedSearch, IUserProfile } from "Types/Entity/UserDataEntity";
import { GET_PROFILE_F, GET_PROFILE_S, GET_LIST_FAVOURITE_F, GET_LIST_FAVOURITE_S, GET_SAVED_SEARCH_F, GET_SAVED_SEARCH_S, GET_LIST_MANAGE_ADS_S, GET_LIST_MANAGE_ADS_F, GET_CAR_POST_DETAIL_S, GET_CAR_POST_DETAIL_F, GET_PART_POST_DETAIL_S, GET_PART_POST_DETAIL_F } from "constants/redecuerType";

export interface IUserDataSlice {
  userProfile: IUserProfile,
  savedSearchList: {
    totalPages: 0,
    list: ISavedSearch[]
  }
  favouritePost: {
    totalPages: 0,
    list: IFavouritePost[]
  }
  manageAds: {
    totalPages: 0,
    list: IManageAds[],
    carPostDetail: ICarPostDetail,
    partPostDetail: IPartDetail,
  },
}

const initialState: IUserDataSlice = {
  savedSearchList: {
    totalPages: 0,
    list: []
  },
  userProfile: {
    name: '',
    gender: '',
    email: '',
    phoneNumber: '',
    nationality: '',
    location: '',
    profileStatus: 1,
    profileStatusLabel: '',
    counts: {
      ads: 0,
      favorites: 0,
      searches: 0
    },
    isActive: true,
    userImageUrl: ''
  },
  favouritePost: {
    totalPages: 0,
    list: []
  },
  manageAds: {
    totalPages: 0,
    list: [],
    carPostDetail: {},
    partPostDetail: {}
  }
}
// Reducer
const userDataSlice = createSlice({
  name: "userData",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GET_SAVED_SEARCH_S, (state, action: any) => {
      state.savedSearchList.list = action.payload?.data || []
      state.savedSearchList.totalPages = action.payload?.totalPages || 0

    });
    builder.addCase(GET_SAVED_SEARCH_F, (state, action: any) => {
      state.savedSearchList.list = []
      state.savedSearchList.totalPages = 0
    });
    builder.addCase(GET_PROFILE_S, (state, action: any) => {
      state.userProfile = action.payload

    });
    builder.addCase(GET_PROFILE_F, (state, action: any) => {
      state.userProfile = {
        name: '',
        gender: '',
        email: '',
        phoneNumber: '',
        nationality: '',
        location: '',
        profileStatus: 1,
        profileStatusLabel: '',
        counts: {
          ads: 0,
          favorites: 0,
          searches: 0
        },
        isActive: true,
        userImageUrl: ''
      }
    });

    builder.addCase(GET_LIST_FAVOURITE_S, (state, action: any) => {
      state.favouritePost.list = action.payload?.data || []
      state.favouritePost.totalPages = action.payload?.totalPages || 0

    });
    builder.addCase(GET_LIST_FAVOURITE_F, (state, action: any) => {
      state.favouritePost.list = []
      state.favouritePost.totalPages = 0
    });

    builder.addCase(GET_LIST_MANAGE_ADS_S, (state, action: any) => {
      state.manageAds.list = action.payload?.data || []
      state.manageAds.totalPages = action.payload?.totalPages || 0

    });
    builder.addCase(GET_LIST_MANAGE_ADS_F, (state, action: any) => {
      state.manageAds.list = []
      state.manageAds.totalPages = 0
    });
    builder.addCase(GET_CAR_POST_DETAIL_S, (state, action: any) => {
      state.manageAds.carPostDetail = action.payload || {}
    });
    builder.addCase(GET_CAR_POST_DETAIL_F, (state, action: any) => {
      state.manageAds.carPostDetail = action.payload || {}
    });
    builder.addCase(GET_PART_POST_DETAIL_S, (state, action: any) => {
      state.manageAds.partPostDetail = action.payload || {}
    });
    builder.addCase(GET_PART_POST_DETAIL_F, (state, action: any) => {
      state.manageAds.partPostDetail = action.payload || {}
    });
  },
});

export default userDataSlice.reducer;
