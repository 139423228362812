// Authenticate
export const API_LOGIN: string = `api/auth/login`;
export const API_SIGNUP: string = `api/auth/register`;
export const API_FORGOT_PASSWORD: string = `api/auth/password/forgot`;
export const API_CHANGE_PASSWORD: string = `api/auth/password/change`;
export const API_REFRESH_TOKEN: string = `api/auth/refresh-authentication-result`;
export const API_RESEND_OTP: string = `api/auth/otp/email`;
export const API_SOCIAL_LOGIN_REG: string = `admin/socialLoginReg`;
export const API_RESET_PASSWORD: string = `api/auth/password/reset`;
export const API_EMAIL_VERIFY: string = `api/auth/verify-email`;
export const API_MOBILE_SEND_OTP: string = `api/auth/otp/mobile`;

// Post
export const API_POST_VIN_DETAIL: string = `api/draftpost/vin`;
export const API_ADD_DRAFT: string = `api/draftpost`;
export const API_EDIT_DRAFT: string = `api/draftpost`;
export const API_GET_DRAFT_DETAIL: string = `api/draftpost`;
export const API_GET_DRAFT_META: string = `api/draftpost/metadata`;
export const API_GET_PACKAGE: string = `api/package/car`;
export const API_GET_DRAFT_SUMMARY: string = `api/draftpost/review`;
export const API_CAR_CHECKOUT: string = `api/payment/submit-draft`;
export const API_OTP_VERIFY: string = `api/draftpost/verify-mobile-otp`;
export const API_GET_CAR_DETAILS: string = `api/posts/`;

// Post Dropdown
export const API_GET_BODY_CONDITION: string = `api/bodyCondition`;
export const API_GET_BODY_TYPE: string = `api/bodytype`;
export const API_GET_CAR_COLOR: string = `api/car-color`;
export const API_GET_CAR_MODEL: string = `api/carmodel`;
export const API_GET_CITY: string = `api/city`;
export const API_GET_HORSE_POWER: string = `api/horsepower`;
export const API_GET_INTERIOR_COLOR: string = `api/interiorcolor`;
export const API_GET_MAKE: string = `api/car-make`;
export const API_GET_MECHENICAL_CONDITION: string = `api/mechenical-condition`;
export const API_GET_USAGE: string = `api/usage`;

// Image Upload 
export const API_UPLOAD_IMAGE: string = `api/attachment/upload`;

// Accessory
export const API_GET_ACCESSORY_TYPE: string = `api/accessory/type`;
export const API_GET_ACCESSORY_SUBTYPE: string = `api/accessory/subtype`;
export const API_ADD_ACCESSORY: string = `api/posts/accessory`;
export const API_GET_ACCESSORY: string = `api/posts/accessory`;
export const API_GET_ACCESSORY_REVIEW: string = `api/posts/accessory/review`;
export const API_EDIT_ACCESSORY: string = `api/posts/accessory`;
export const API_GET_ACCESSORY_PACKAGE: string = `api/package/accessory`;

export const API_GET_SEARCH_RESULT: string = `api/Posts/Search`;

//Home 
export const API_HOME: string = `api/posts/home`
export const API_GET_COUNTRIES: string = `api/countries`

// Save Search
export const API_ADD_SAVE_SEARCH: string = `api/posts/savedsearch`;
export const API_DELETE_SAVE_SEARCH: string = `api/posts/savedsearch`;
export const API_RENAME_SAVE_SEARCH: string = `api/posts/savedsearch`;
export const API_GET_SAVED_SEARCH: string = `api/posts/savedsearch/search`;

// Favourite APIs
export const API_ADD_POST_FAV: string = `api/posts/favourite`;
export const API_REMOVE_POST_FAV: string = `api/posts/favourite`;
export const API_REMOVE_POST_FAV_LIST: string = `api/posts/favourite/list`;
export const API_LIST_POST_FAV: string = `api/posts/favourite/list/search`;
export const API_REMOVE_LIST_POST_FAV: string = `api/posts/favourite/list`;

// Profile 
export const API_GET_PROFILE: string = `api/user/profile`;

// Manage Ads
export const API_MANAGE_ADS_LIST: string = `api/posts/my-ad/list`;
export const API_REMOVE_MANAGE_ADS: string = `api/posts/my-ad`;
export const API_GET_CAR_POST_DETAIL: string = `api/posts/my-ad`;
export const API_GET_CAR_POST_DETAIL_REVIEW: string = `api/posts`;
export const API_EDIT_CAR_POST_DETAIL: string = `api/posts/my-ad`;
export const API_PART_CAR_POST_DETAIL: string = `api/posts/accessory`;
export const API_PART_CAR_POST_REVIEW: string = `api/posts/accessory/review`;
export const API_EDIT_PART_POST_DETAIL: string = `api/posts/accessory`;
export const API_CHANGE_POST_STATUS: string = `api/posts/status`;

// Contact Us
export const API_CONTACT: string = `api/contactus`;

// Cms 
export const API_CMS: string = `api/cms`;
export const API_FAQ: string = `api/faq`;