// import { StrictMode } from "react";
import App from "./view/App";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "services/store";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    // <StrictMode>
    <Provider store={store}>
        <App />
    </Provider>
    // </StrictMode >
);