import { authFail } from 'services/AuthSlice';
import { REFRESH_TOKEN_S, REFRESH_TOKEN_F } from './../../constants/redecuerType';
import { API_MOBILE_SEND_OTP, API_REFRESH_TOKEN, API_RESEND_OTP } from './../../constants/api';
import { API_CHANGE_PASSWORD, API_EMAIL_VERIFY, API_FORGOT_PASSWORD, API_LOGIN, API_RESET_PASSWORD, API_SIGNUP, API_SOCIAL_LOGIN_REG } from 'constants/api';
import { FORGOT_PASS_F, FORGOT_PASS_S, LOGIN_F, LOGIN_S, RESET_PASS_F, RESET_PASS_S, SIGNUP_S, SOCIAL_LOGIN_REG_F, SOCIAL_LOGIN_REG_S } from 'constants/redecuerType';
import { IChangePasswordApiParam, IForGotPasswordApiParam, ILoginApiParam, IResetPasswordApiParam, ISignUpApiParam } from 'Types/Entity/AuthEntity';

export const loginAction: any = (data: ILoginApiParam) => ({
    type: "API",
    payload: {
        url: API_LOGIN,
        method: "POST",
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: LOGIN_S,
            payload: data,
        }),
        error: (data) => ({
            type: LOGIN_F,
            payload: {},
        }),
    },
});

export const signUpAction: any = (data: ISignUpApiParam) => ({
    type: "API",
    payload: {
        url: API_SIGNUP,
        method: "POST",
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: SIGNUP_S,
            payload: data,
        }),
        error: (data) => (authFail({})),
    },
});

export const socialSignUpAction: any = (data: ISignUpApiParam) => ({
    type: "API",
    payload: {
        url: API_SIGNUP,
        method: "POST",
        data: data,
        hideLoader: false,
    },
});

export const forgotPasswordAction: any = (data) => ({
    type: "API",
    payload: {
        url: API_FORGOT_PASSWORD + data,
        method: "POST",
        hideLoader: false,
        success: (data) => ({
            type: FORGOT_PASS_S,
            payload: data,
        }),
        error: (data) => ({
            type: FORGOT_PASS_F,
            payload: {},
        }),
    },
});

export const socialLoginRegAction: any = (data: IForGotPasswordApiParam) => ({
    type: "API",
    payload: {
        url: API_SOCIAL_LOGIN_REG,
        method: "POST",
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: SOCIAL_LOGIN_REG_S,
            payload: data,
        }),
        error: (data) => ({
            type: SOCIAL_LOGIN_REG_F,
            payload: {},
        }),
    },
});

export const resetPasswordAction: any = (data: IResetPasswordApiParam) => ({
    type: "API",
    payload: {
        url: API_RESET_PASSWORD,
        method: "POST",
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: RESET_PASS_S,
            payload: data,
        }),
        error: (data) => ({
            type: RESET_PASS_F,
            payload: {},
        }),
    },
});

export const emailVerifyAction: any = (data) => ({
    type: "API",
    payload: {
        url: API_EMAIL_VERIFY + data,
        method: "POST",
        hideLoader: false,
    },
});

export const changePasswordAction: any = (data: IChangePasswordApiParam) => ({
    type: "API",
    payload: {
        url: API_CHANGE_PASSWORD,
        method: "POST",
        data: data,
        hideLoader: false,
    },
});

export const refreshTokenAction: any = (data) => ({
    type: "API",
    payload: {
        url: API_REFRESH_TOKEN + `?refreshToken=${data}`,
        method: "POST",
        data: data,
        hideLoader: true,
        success: (data) => ({
            type: REFRESH_TOKEN_S,
            payload: data,
        }),
        error: (data) => ({
            type: REFRESH_TOKEN_F,
            payload: {},
        }),
    },
});

export const resendOtp: any = (data) => ({
    type: "API",
    payload: {
        url: API_RESEND_OTP,
        method: "POST",
        data: data,
        hideLoader: false,
    },
});


export const mobileVerifyAction: any = (data) => ({
    type: "API",
    payload: {
        url: API_MOBILE_SEND_OTP,
        method: "POST",
        hideLoader: false,
        data: data
    },
});



