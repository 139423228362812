import { LANGUAGE, LOGIN_REDIRECTION_PATH, THEMES } from "constants/common";
import { useEffect } from "react";
import { authFail, authSuccess, languageChange, themeChange } from "services/AuthSlice";
import { store } from "services/store";
import { API_BASE, LS_AUTHORED, LS_LANGUAGE, LS_THEME, LS_USER } from "../constants";
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { refreshTokenAction } from "services/AuthSlice/action";
import { ApiResponse } from "Types/Common/Common";

//To concat the path for the public folder
export const toAbsoluteUrl = (pathname) => window.location.origin + `${pathname}`;

// Fun used for setting up the common header for axios through out the app and rehydrate the redux store
export const setupAxios = (axios, store) => {

  if (localStorage.getItem(LS_AUTHORED) && localStorage.getItem(LS_USER)) {
    const token = JSON.parse(localStorage.getItem(LS_AUTHORED) || '');
    const userData = JSON.parse(localStorage.getItem(LS_USER) || '');

    // It's used to rehydrate redux auth data when page is refreshed
    if (token) {
      let USER_DATA = { ...userData, token: token };
      store.dispatch(authSuccess(USER_DATA));
    } else {
      store.dispatch(authFail({}));
    }
  }

  // Function that will be called to refresh authorization
  const refreshAuthLogic = async (failedRequest) => {
    const userData = JSON.parse(localStorage.getItem(LS_USER) || '');
    if (userData?.refreshToken) {
      store.dispatch(refreshTokenAction(userData?.refreshToken))
        .then((res: ApiResponse) => {
          failedRequest.response.config.headers['Authorization'] = 'Bearer ' + res?.result?.accessToken;
          axios.defaults.headers.common["Authorization"] = `Bearer ${res?.result?.accessToken}`;
          localStorage.setItem(LS_AUTHORED, JSON.stringify(res?.result?.accessToken));
          window.location.reload()
          return Promise.resolve();
        })
        .catch((err) => {
          console.log('err error: ', err);
          store.dispatch(authFail({}))
          window.location.replace(LOGIN_REDIRECTION_PATH(window.location.pathname))
          return Promise.reject();
        })
    } else {
      store.dispatch(authFail({}))
      window.location.replace(LOGIN_REDIRECTION_PATH(window.location.pathname))
    }
  }
  createAuthRefreshInterceptor(axios, refreshAuthLogic, {
    // pauseInstanceWhileRefreshing: true,
    onRetry: (requestConfig) => ({ ...requestConfig, baseURL: API_BASE }),
    statusCodes: [401]
  });
};

// Setup initial theme and language from localstore
export const setupLangTheme = (store) => {
  const lang = localStorage.getItem(LS_LANGUAGE) || '';
  const theme = localStorage.getItem(LS_THEME) || '';

  // It's used to set stored language and theme for redux
  if (theme) {
    store.dispatch(themeChange(theme))
  }
  if (lang) {
    store.dispatch(languageChange(lang))
  }
  if (lang === LANGUAGE.AR) {
    document.body.classList.add("arabicRTL");
  }
  else {
    document.body.classList.remove("arabicRTL");
  }
};

export const onThemeChange = (currentTheme) => {
  if (currentTheme === THEMES.LIGHT) {
    setTimeout(() => {
      localStorage.setItem(LS_THEME, THEMES.DARK)
      store.dispatch(themeChange(THEMES.DARK))
    }, 100);

  } else {
    setTimeout(() => {
      localStorage.setItem(LS_THEME, THEMES.LIGHT)
      store.dispatch(themeChange(THEMES.LIGHT))
    }, 100);
  }
}

// outside click
export const OutSideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref?.current && !ref?.current?.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};
