import { createGlobalStyle } from "styled-components";
import { device } from "./breakpoints";

const Common = createGlobalStyle`

//Fonts Size

// h1 {
    
// }
// h2 {
// }

// h3 {
   
// }
// h4 {
    
// h5 {
    
// }
// p {
    
// }

/*--- Margin and Padding ---*/

//padding
.p-0{
    padding: 0;
}
.pr-0{
    padding-right: 0;
}
.pl-0{
    padding-left: 0;
}
.pt-0{
    padding-right: 0;
}
.pb-0{
    padding-left: 0;
}

// margin
.m-0{
    padding: 0;
}
.mr-0{
    padding-right: 0;
}
.ml-0{
    padding-left: 0;
}
.mt-0{
    padding-right: 0;
}
.mb-0{
    padding-left: 0;
}

.text-center {
    text-align: center;
}

.textRight {
    text-align: right;
}

.text-success {
    color: --success--color;
}

.textDanger {
    color: #d33520;
}

.text-warning {
    color: --warning--color;
}

.text-info {
    color: --info--color;
}

// Width css
.w-100 {
    width: 100%;
}

// Height SCSS
.min-h100 {
    min-height: 100vh;
}

.h-100 {
    height: 100%;
}

.d-none{
 display: none;
}

.container{
    width: 1440px;
    max-width: 100%;
    padding-left: 66px;
    padding-right: 66px;
    margin: 0 auto;
}

.container-full {
    width: 100%;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
}

.inputError{
    font-size: 14px;
    font-weight: 400;
    text-align: right;
    margin: 5px 20px 0;
}

.rc-slider {
    .rc-slider-rail {
      background-color: #d9d9d9;
    }
    .rc-slider-track {
      height: 6px;
      background-color: ${({
        theme: {
          colors: { common },
        },
      }) => common.secondary};
    }
    .rc-slider-handle {
      width: 17px;
      height: 17px;
      border: 1px solid
        ${({
          theme: {
            colors: { common },
          },
        }) => common.secondary};
      background: ${({
        theme: {
          colors: { common },
        },
      }) => common.secondary};
      opacity: 1;
      margin-top: -6px;
    }
  }

  ::-webkit-scrollbar {
      background-color: transparent;
      width: 3px;
      height: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #cccccc;
      border-radius: 50px;
    }

  /* rtl css */
  .arabicRTL{
  direction: rtl;

  /* auth */
  .paswordIconWrap{
    left: 20px;
    right: auto;
  }

  /* input feilds */
  .formLabel{
    right: 27px;
    left: auto;
  }

  .formInput:focus ~ .formLabel{
    right: 27px;
    left: auto;
  }

  .formInput:not(:placeholder-shown).formInput:not(:focus) ~ .formLabel{
    right: 27px;
    left: auto;
  }

  .selectFloating .demoMain{
    background-position: left 20px center;
  }
  .selectFloating .floatingFormLabel{
    right: 24px;
    left: auto;
  }

    /* banner-landing page */
    .banner::before{
    left: 0;
    right: auto;
  }

  /* banner filter */
  .filterBar li .filterDropdown{
    left: auto;
    right: 0 !important;
  }
  .filterBar li::after{
       transform: translate(-180px);
  }
  .filterTabWrap .filterBar li{
     .priceDropdown{
       right: calc(100% - 270px);
       left: auto;
     }
   }
   .filterBar li::after{
    right: 0 !important;
   }

   /* featured car */
   .carSmallDetails:first-of-type svg{
    margin: 0 0 0 9px;
   }
   .likeCard{
    left: 10px;
    right: auto;
   }
   

   /* stepper */
   .stepperCardWrap .stepperForm ul .location .childrenClass{
    left: 30px;
    right: auto;
   }
   #RFS-ConnectorContainer{
    right: calc((-50% + 30px) - 8px);
    left: calc((50% + 30px) - 8px);
   }
   /* payment */
   .orderSummaryWrap .checkboxList input[type="checkbox"]:checked:after{
    right: 6px;
   }

   /* manageads */
   .manageAds {
     .adsTabsList li:first-of-type{
        margin: 0 0 0 30px;
     }
     .tabDropdown .dropdownLabel svg{
      margin: 0 10px 0 0;
     }
   }

   /* saveSearch */
   .saveSearch{
    .saveSearchCard .cardHeader .tabDropdown .dropdownLabel svg{
      margin: 0 10px 0 0;
    }
   }

   /* favorite */
   .myFavorite .favoriteCard .cardHeader .carListWrap ul li{
    max-width: 141px;
   }

   .myFavorite .favoriteCard .cardHeader .carListWrap ul li:first-of-type{
    margin: 0;
   }

   /* profile */
  .profileDetiails {
    margin-right: 50px;
  }

  .profileCount li::after{
    right: auto;
    left: 0;
  }

  /* save search */
  .searchRelevanceBtn span{
    margin: 0 7px 0 0;
  }

  .searchRelevanceDropdown .dropdownLabel .selectedFilterText{
    margin-right: 3px;
    margin-left: 0;
  }

  .searchRelevanceDropdown .dropdownLabel svg{
    margin: 0 10px 0 0px;
  }

  input[type="checkbox"]:checked:after{
    left: 1px;
  }

  .featuredWrap{
    border-radius: 3px 0 0 3px;
  }

  /* no result */
  .textContent{
      margin-left: auto;
      margin-right: 5%;
  }
}

@media only screen and (${device.xxl}) {
  .container{
    width: 1600px;
  }
}

@media only screen and (${device.xl}) {
    .container{
    width: 1140px;
    padding: 0 30px;
}
/* arabic */
.arabicRTL{
  .filterBar li::after{
         transform: translate(-170px);
    }

       /* manageads */
    .adsTabsList li:first-of-type{
        margin: 0 0 0 28px;
     }

   /* saveSearch */
   .saveSearch{
    .saveSearchCard .cardHeader .tabDropdown .dropdownLabel svg{
      margin: 0 9px 0 0;
    }
   }     

   /* profile */
   .profileDetiails {
    margin-right: 45px;
  }
}
}
@media only screen and (${device.lg}) {
  .container{
    width: auto;
    padding: 0 15px;
  }
  /* arabic */
  .arabicRTL{
    .filterBar li::after{
         transform: translate(-160px);
    } 

       /* manageads */
    .adsTabsList li:first-of-type{
        margin: 0 0 0 26px;
     }

        /* saveSearch */
   .saveSearch{
    .saveSearchCard .cardHeader .tabDropdown .dropdownLabel svg{
      margin: 0 8px 0 0;
    }
   }  
  }
}

@media only screen and (${device.md}) {
  .arabicRTL{
    /* manageads */
    .adsTabsList li:first-of-type{
        margin: 0 0 0 22px;
     }

                  /* saveSearch */
                  .saveSearch{
       .saveSearchCard .cardListWrap .mainCard .btnGrp button:first-of-type{
        margin-left: 15px;
        margin-right: 0;
      }
     }
  }
}

@media only screen and (${device.sm}) {
  .arabicRTL{
    /* manageads */
    .adsTabsList li:first-of-type{
        margin: 0 0 0 15px;
     }

             /* saveSearch */
     .saveSearch{
       .saveSearchCard .cardListWrap .mainCard .btnGrp button:first-of-type{
        margin-left: 15px;
        margin-right: 0;
      }
     }

        /* profile */
   .profileDetiails {
    margin-right: 0px;
  }
  }
}

@media only screen and (${device.xs}) {
  .arabicRTL{
    /* manageads */
    .adsTabsList li:first-of-type{
        margin: 0 0 0 10px;
     }
  }
}

`;

export default Common;
