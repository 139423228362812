const ComingSoonIcon = () => {
  return (
    <>
      <svg
        width="57"
        height="83"
        viewBox="0 0 57 83"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28.5007 58.5625C27.9227 60.3253 25.84 61.8906 22.6868 62.8718C14.6792 65.3637 9.72504 68.9184 8.76562 72.7812H48.2358C47.276 68.9184 42.3222 65.3637 34.3146 62.8718C31.1614 61.8906 29.0787 60.3253 28.5007 58.5625Z"
          fill="#434343"
        />
        <path
          d="M11.4551 18.75C13.8421 23.2964 17.6633 27.3666 22.6861 30.5782C24.2269 31.5579 25.5419 32.8538 26.5442 34.38C27.5465 35.9062 28.213 37.628 28.4998 39.4312C28.7866 37.628 29.4532 35.9062 30.4554 34.38C31.4577 32.8538 32.7727 31.5579 34.3135 30.5782C39.3363 27.3666 43.1576 23.2964 45.5445 18.75H11.4551Z"
          fill="#434343"
        />
        <path
          d="M37.3777 35.3694C47.5542 28.8631 54.0938 18.7427 54.0938 7.375C54.848 7.375 55.5713 7.07539 56.1046 6.54209C56.6379 6.00878 56.9375 5.28546 56.9375 4.53125V0.265625H0.0625V4.35352C0.0628362 5.12362 0.357476 5.86448 0.88612 6.42448C1.41476 6.98448 2.13744 7.32128 2.90625 7.36594V7.375C2.90625 18.7427 9.44581 28.8631 19.6223 35.3694C24.094 38.2284 24.094 44.7716 19.6223 47.6306C9.44581 54.1369 2.90625 64.2573 2.90625 75.625V75.6341C2.13744 75.6787 1.41476 76.0155 0.88612 76.5755C0.357476 77.1355 0.0628362 77.8764 0.0625 78.6465V82.7344H56.9375V78.4688C56.9375 77.7145 56.6379 76.9912 56.1046 76.4579C55.5713 75.9246 54.848 75.625 54.0938 75.625C54.0938 64.2573 47.5542 54.1369 37.3777 47.6306C32.906 44.7716 32.906 38.2284 37.3777 35.3694ZM31.1802 41.5C31.1756 43.1994 31.601 44.8723 32.4167 46.3631C33.2324 47.8539 34.4121 49.1141 35.8458 50.0265C45.6354 56.2854 51.25 65.6159 51.25 75.625H5.75C5.75 65.6159 11.3646 56.2854 21.1542 50.0265C22.5847 49.1107 23.7618 47.8498 24.5772 46.3597C25.3925 44.8697 25.8198 43.1985 25.8198 41.5C25.8198 39.8015 25.3925 38.1303 24.5772 36.6403C23.7618 35.1502 22.5847 33.8893 21.1542 32.9735C11.3646 26.7146 5.75 17.3841 5.75 7.375H51.25C51.25 17.3841 45.6354 26.7146 35.8458 32.9735C34.4121 33.8859 33.2324 35.1461 32.4167 36.6369C31.601 38.1277 31.1756 39.8006 31.1802 41.5Z"
          fill="#434343"
        />
        <path
          d="M28.501 47.0973C28.8545 47.0973 29.1936 46.9569 29.4436 46.7069C29.6935 46.4569 29.834 46.1178 29.834 45.7643V44.3447C29.834 43.9912 29.6935 43.6521 29.4436 43.4021C29.1936 43.1522 28.8545 43.0117 28.501 43.0117C28.1474 43.0117 27.8084 43.1522 27.5584 43.4021C27.3084 43.6521 27.168 43.9912 27.168 44.3447V45.7643C27.168 46.1178 27.3084 46.4569 27.5584 46.7069C27.8084 46.9569 28.1474 47.0973 28.501 47.0973Z"
          fill="#434343"
        />
        <path
          d="M27.168 52.8737C27.168 53.2272 27.3084 53.5663 27.5584 53.8162C27.8084 54.0662 28.1474 54.2067 28.501 54.2067C28.8545 54.2067 29.1936 54.0662 29.4436 53.8162C29.6935 53.5663 29.834 53.2272 29.834 52.8737V51.4541C29.834 51.1006 29.6935 50.7615 29.4436 50.5115C29.1936 50.2615 28.8545 50.1211 28.501 50.1211C28.1474 50.1211 27.8084 50.2615 27.5584 50.5115C27.3084 50.7615 27.168 51.1006 27.168 51.4541V52.8737Z"
          fill="#434343"
        />
      </svg>
    </>
  );
};

export default ComingSoonIcon;
