import { device } from "style/breakpoints";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${({
    theme: {
      colors: { common },
    },
  }) => common.backGround4};

  .comingsoonFigure {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    background-color: ${({
      theme: {
        colors: { common },
      },
    }) => common.secondary};
    margin-bottom: 20px;

    svg {
      stroke: ${({
        theme: {
          colors: { common },
        },
      }) => common.btnBgColor};
      path {
        fill: ${({
          theme: {
            colors: { common },
          },
        }) => common.btnBgColor};
      }
    }
  }

  h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    color: ${({
      theme: {
        colors: { common },
      },
    }) => common.secondary};
  }

  @media only screen and (${device.xl}) {
    h1 {
      font-size: 38px;
    }
  }
  @media only screen and (${device.lg}) {
    h1 {
      font-size: 36px;
    }
  }
  @media only screen and (${device.md}) {
    h1 {
      font-size: 34px;
    }
  }
  @media only screen and (${device.sm}) {
    h1 {
      font-size: 32px;
    }
  }
  @media only screen and (${device.xs}) {
    h1 {
      font-size: 30px;
    }
  }
`;
