import { Link, useNavigate } from "react-router-dom";
import { Wrapper } from "./style";
import { useTranslation } from "react-i18next";

interface IlistObject {
  name: string;
  link: string | number;
  isActive: boolean
}
interface IProps {
  list: IlistObject[]
}
const BreadCrumbs = (props: IProps) => {
  const { list } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const onLinkClick = (goToLink) => {
    navigate(goToLink)
  }

  return (
    <Wrapper className="breadcrumbsGrp">
      <ul className="breadList">
        <li className="home">
          <Link to="/">
            <h4>{t("home")} {">"}</h4>
          </Link>
        </li>
        {list?.map((item, i) => (
          <li key={i}>
            {!item.isActive ? (
              <Link to="#" onClick={(e) => { e.preventDefault(); onLinkClick(item.link) }} >
                <h4 className="bredcrumbsLabel">
                  {item.name} {">"}
                </h4>
              </Link>
            ) : (
              <h4 className="bredcrumbsLabel">{item.name}</h4>
            )}
          </li>
        ))}
      </ul>
    </Wrapper>
  );
};

export default BreadCrumbs;
