
export const ROUTES = {
    // Open Routes
    resetPassword: `/auth/resetPassword`,
    mobilePayment: `/mobilePayment`,
    comingSoon: `/comingSoon`,

    default: `/`,
    // Auth Routes
    signInOption: `/auth/signinOption`,
    signIn: `/auth/signin`,
    signUp: `/auth/signup`,
    emailVerification: `/auth/emailVerification`,
    mobileVerification: `/auth/mobileVerification`,
    changePassword: `/auth/changePassword`,
    forgotPassword: `/auth/forgotPassword`,
    searchResult: `/searchResult`,
    autoPartsSearchResult: `/autoPartsSearchResult`,
    postDetail: `/postDetail`,
    autoPartDetail: `/autoPartDetails`,
    savedSearch: `/saveSearch`,
    profile: `/auth/profile`,
    favourite: `/favourite`,
    // Post Routes
    selectProducts: `/post/selectProducts`,
    productType: `/post/productType`,
    carNumber: `/post/carNumber`,
    chassisNumber: `/post/chassisNumber`,
    carDetail: `/post/carDetail`,
    payment: `/post/checkout`,
    paymentAccessory: `/post/accessoryCheckout`,
    manageAds: `/post/manageAds`,
    partDetail: `/post/partDetail`,
    accessoryType: `/post/type`,
    accessorySubType: `/post/subType`,
    EditPost: `/post/editPost`,

    //Contact
    contact: `/contact`,

    // Cms
    faq: `/cms/faq`,
    cms: `/cms`,
}