import { API_CMS, API_FAQ, API_GET_ACCESSORY_SUBTYPE, API_GET_ACCESSORY_TYPE, API_GET_COUNTRIES, API_GET_SEARCH_RESULT, API_GET_USAGE, API_HOME, API_UPLOAD_IMAGE } from './../../constants/api';
import { API_GET_BODY_CONDITION, API_GET_BODY_TYPE, API_GET_CAR_COLOR, API_GET_CAR_MODEL, API_GET_CITY, API_GET_HORSE_POWER, API_GET_INTERIOR_COLOR, API_GET_MAKE, API_GET_MECHENICAL_CONDITION } from "constants/api";
import { PRODUCTS } from 'constants/common';
import { ACCESSORY_SUBTYPE_F, ACCESSORY_SUBTYPE_S, ACCESSORY_TYPE_F, ACCESSORY_TYPE_S, BODY_CONDITION_F, BODY_CONDITION_S, BODY_TYPE_F, BODY_TYPE_S, CAR_COLOR_F, CAR_COLOR_S, CAR_MODEL_F, CAR_MODEL_S, CITY_F, CITY_S, COUNTRY_F, COUNTRY_S, GET_CMS_S, GET_FAQ_F, GET_FAQ_S, GET_HOME_F, GET_HOME_S, GET_SEARCH_RESULT_F, GET_SEARCH_RESULT_S, HORSE_POWER_F, HORSE_POWER_S, INTERIOR_COLOR_F, INTERIOR_COLOR_S, MAKE_F, MAKE_S, MECHENICAL_CONDITION_F, MECHENICAL_CONDITION_S, USAGE_F, USAGE_S } from "constants/redecuerType";

export const getBodyConditionAction = () => ({
    type: "API",
    payload: {
        url: API_GET_BODY_CONDITION,
        method: "GET",
        hideLoader: true,
        success: (data) => ({
            type: BODY_CONDITION_S,
            payload: data,
        }),
        error: (data) => ({
            type: BODY_CONDITION_F,
            payload: [],
        }),
    },
});

export const getBodyTypeAction = () => ({
    type: "API",
    payload: {
        url: API_GET_BODY_TYPE,
        method: "GET",
        hideLoader: true,
        success: (data) => ({
            type: BODY_TYPE_S,
            payload: data,
        }),
        error: (data) => ({
            type: BODY_TYPE_F,
            payload: [],
        }),
    },
});

export const getCityAction = () => ({
    type: "API",
    payload: {
        url: API_GET_CITY,
        method: "GET",
        hideLoader: true,
        success: (data) => ({
            type: CITY_S,
            payload: data,
        }),
        error: (data) => ({
            type: CITY_F,
            payload: [],
        }),
    },
});

export const getCarMakeAction = () => ({
    type: "API",
    payload: {
        url: API_GET_MAKE,
        method: "GET",
        hideLoader: true,
        success: (data) => ({
            type: MAKE_S,
            payload: data,
        }),
        error: (data) => ({
            type: MAKE_F,
            payload: [],
        }),
    },
});

export const getCarColorAction = () => ({
    type: "API",
    payload: {
        url: API_GET_CAR_COLOR,
        method: "GET",
        hideLoader: true,
        success: (data) => ({
            type: CAR_COLOR_S,
            payload: data,
        }),
        error: (data) => ({
            type: CAR_COLOR_F,
            payload: [],
        }),
    },
});

export const getCarModelAction = () => ({
    type: "API",
    payload: {
        url: API_GET_CAR_MODEL,
        method: "GET",
        hideLoader: true,
        success: (data) => ({
            type: CAR_MODEL_S,
            payload: data,
        }),
        error: (data) => ({
            type: CAR_MODEL_F,
            payload: [],
        }),
    },
});

export const getHorsePowerAction = () => ({
    type: "API",
    payload: {
        url: API_GET_HORSE_POWER,
        method: "GET",
        hideLoader: true,
        success: (data) => ({
            type: HORSE_POWER_S,
            payload: data,
        }),
        error: (data) => ({
            type: HORSE_POWER_F,
            payload: [],
        }),
    },
});

export const getInteriorColorAction = () => ({
    type: "API",
    payload: {
        url: API_GET_INTERIOR_COLOR,
        method: "GET",
        hideLoader: true,
        success: (data) => ({
            type: INTERIOR_COLOR_S,
            payload: data,
        }),
        error: (data) => ({
            type: INTERIOR_COLOR_F,
            payload: [],
        }),
    },
});

export const getMechenicalConditionAction = () => ({
    type: "API",
    payload: {
        url: API_GET_MECHENICAL_CONDITION,
        method: "GET",
        hideLoader: true,
        success: (data) => ({
            type: MECHENICAL_CONDITION_S,
            payload: data,
        }),
        error: (data) => ({
            type: MECHENICAL_CONDITION_F,
            payload: [],
        }),
    },
});

export const uploadImageAction: any = (data) => ({
    type: "API",
    payload: {
        url: API_UPLOAD_IMAGE,
        method: "POST",
        hideLoader: true,
        data: data,
    },
});

export const getUsageAction = () => ({
    type: "API",
    payload: {
        url: API_GET_USAGE,
        method: "GET",
        hideLoader: true,
        success: (data) => ({
            type: USAGE_S,
            payload: data,
        }),
        error: (data) => ({
            type: USAGE_F,
            payload: [],
        }),
    },
});

export const accessoryTypeAction = () => ({
    type: "API",
    payload: {
        url: API_GET_ACCESSORY_TYPE,
        method: "GET",
        hideLoader: true,
        success: (data) => ({
            type: ACCESSORY_TYPE_S,
            payload: data,
        }),
        error: (data) => ({
            type: ACCESSORY_TYPE_F,
            payload: [],
        }),
    },
});


export const accessorySubTypeAction = (data) => ({
    type: "API",
    payload: {
        url: API_GET_ACCESSORY_SUBTYPE + data,
        method: "GET",
        hideLoader: false,
        success: (data) => ({
            type: ACCESSORY_SUBTYPE_S,
            payload: data,
        }),
        error: (data) => ({
            type: ACCESSORY_SUBTYPE_F,
            payload: [],
        }),
    },
});

export const getSearchResult: any = (data) => ({
    type: "API",
    payload: {
        url: API_GET_SEARCH_RESULT + `?PageNumber=${data?.page || 1}&PageSize=${data?.limit}&Sorting=${data?.sorting || ''}`,
        method: "POST",
        hideLoader: true,
        data,
        success: (data) => ({
            type: GET_SEARCH_RESULT_S,
            payload: { ...data, postType: data?.postType },
        }),
        error: (data) => ({
            type: GET_SEARCH_RESULT_F,
            payload: [],
        }),
    },
});

export const getSearchResultAccessories: any = (data) => ({
    type: "API",
    payload: {
        url: API_GET_SEARCH_RESULT + `?PageNumber=${data?.page || 1}&PageSize=${data?.limit}&Sorting=${data?.sorting || ''}`,
        method: "POST",
        hideLoader: false,
        data,
        success: (data) => ({
            type: GET_SEARCH_RESULT_S,
            payload: { ...data, postType: PRODUCTS.accessories.value },
        }),
        error: (data) => ({
            type: GET_SEARCH_RESULT_F,
            payload: [],
        }),
    },
});

// Home

export const homePageData = () => ({
    type: "API",
    payload: {
        url: API_HOME,
        method: "GET",
        hideLoader: false,
        success: (data) => ({
            type: GET_HOME_S,
            payload: data,
        }),
        error: (data) => ({
            type: GET_HOME_F,
            payload: [],
        }),
    },
});

// Cms
export const getCmsAction = (data) => ({
    type: "API",
    payload: {
        url: API_CMS + data,
        method: "GET",
        hideLoader: false,
        success: (data) => ({
            type: GET_CMS_S,
            payload: data,
        }),
        error: (data) => ({
            type: GET_HOME_F,
            payload: [],
        }),
    },
});

// FAQ
export const getFaqAction = () => ({
    type: "API",
    payload: {
        url: API_FAQ,
        method: "GET",
        hideLoader: false,
        success: (data) => ({
            type: GET_FAQ_S,
            payload: data,
        }),
        error: (data) => ({
            type: GET_FAQ_F,
            payload: [],
        }),
    },
});
// FAQ
export const getCountryList: any = () => ({
    type: "API",
    payload: {
        url: API_GET_COUNTRIES,
        method: "GET",
        hideLoader: false,
        success: (data) => ({
            type: COUNTRY_S,
            payload: data,
        }),
        error: (data) => ({
            type: COUNTRY_F,
            payload: [],
        }),
    },
});

