import React from 'react'
import Col from './col'
import Common from './common'
import Flex from './flex'
import Reset from './reset'
import CarConnectionTheme from "./carConnectionTheme"

const GlobalStyle = () => {

  return (
    <>
        <Col />
        <Common />
        <Flex />
        <Reset />
        <CarConnectionTheme />
    </>
  )
}

export default GlobalStyle