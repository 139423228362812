import { Wrapper } from "./style";

const Card = (props) => {
  return (
    <>
      <Wrapper className={props.className}>
        {props.children}
      </Wrapper>
    </>
  )
}

export default Card;