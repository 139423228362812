import styled from "styled-components";

export const Wrapper = styled.div`
  border-radius: 25px;
  background-color: ${({
    theme: {
      colors: { common },
    },
  }) => common.backGround};
`;
