import { device } from "style/breakpoints";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 82px);
  display: inline-block;
  margin-top: 82px;
  background-color: ${({
    theme: {
      colors: { auth },
    },
  }) => auth.backGround4};
  padding: 40px 0 20px;

  .breadcrumbsGrp {
    margin-bottom: 55px;
  }

  .profileWrap {
    display: flex;
  }

  .profileImage {
    width: 9%;

    .upload__image-wrapper {
      width: 130px;
      height: 130px;
      border-radius: 50%;
      display: flex;
      flex-shrink: 0;
      position: relative;
      border: 1px solid black;

      button {
        background-color: transparent;
        border-radius: 50%;
        cursor: pointer;
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: 15;

        span {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          position: absolute;
          right: 4px;
          bottom: 10px;
          padding: 4px;
          background: ${({
            theme: {
              colors: { auth },
            },
          }) => auth.btnColor};
        }
      }

      .image-item {
        border: none;
        border-radius: 50%;
        flex-shrink: 1;
        height: 100%;
        margin: 0;
        width: 100%;
        img {
          color: transparent;
          height: 100%;
          object-fit: cover;
          padding: 0;
          width: 100%;
          border-radius: 50%;
        }
      }
    }
    .profileName {
      text-align: center;
      margin: 18px 0;
      h3 {
        min-width: 100px;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: ${({
          theme: {
            colors: { auth },
          },
        }) => auth.secondary};
      }
    }
    .logoutBtnWrap {
      button {
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: ${({
          theme: {
            colors: { auth },
          },
        }) => auth.white};
        background-color: ${({
          theme: {
            colors: { auth },
          },
        }) => auth.btnColor};
      }
    }
  }

  .profileDetiails {
    width: 65%;
    margin-left: 50px;
  }

  .profileCard {
    padding: 40px;
    margin-bottom: 30px;
    position: relative;

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    li {
      width: 48%;
      margin-bottom: 30px;
    }

    .updateButton {
      background-color: ${({
        theme: {
          colors: { auth },
        },
      }) => auth.btnColor};
      color: ${({
        theme: {
          colors: { auth },
        },
      }) => auth.white};
      padding: 12px 70px;
    }

    .accountTag {
      position: absolute;
      left: 22px;
      top: -16px;
      .accountDivision {
        border-radius: 25px;
        text-align: center;
        padding: 8px 12px;
        h2 {
          font-size: 16px;
          font-weight: 400;
          line-height: 16px;
        }
      }
      .accountReview {
        background-color: ${({
          theme: {
            colors: { auth },
          },
        }) => auth.redBg};
        color: rgba(236, 0, 0, 0.7);
      }
      .accountVerified {
        width: 180px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: ${({
          theme: {
            colors: { auth },
          },
        }) => auth.blueBg};
        color: #007ef3;
      }
    }
  }

  .profileCount {
    width: 65%;
    ul {
      display: flex;
      align-items: center;
    }
    li {
      width: 222px;
      position: relative;
      text-align: center;
      padding: 23px 0px;
      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 0%;
        bottom: 0;
        margin: auto 0;
        width: 1px;
        height: 100px;
        background: ${({
          theme: {
            colors: { auth },
          },
        }) => auth.textOpacityColor};
      }
      &:last-of-type::after {
        display: none;
      }
      h2 {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: ${({
          theme: {
            colors: { auth },
          },
        }) => auth.secondary};
        margin-bottom: 15px;
      }
      h4 {
        display: inline-block;
        font-size: 30px;
        line-height: 24px;
        font-weight: 600;
        color: ${({
          theme: {
            colors: { auth },
          },
        }) => auth.secondary};
        margin-bottom: 15px;
      }
      h3 {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: ${({
          theme: {
            colors: { auth },
          },
        }) => auth.textOpacityColor};
      }
    }
  }

  @media only screen and (${device.xl}) {
    min-height: calc(100vh - 78px);
    margin-top: 78px;
    padding: 36px 0 18px;

    .breadcrumbsGrp {
      margin-bottom: 50px;
    }

    .profileImage {
      width: 11%;

      .upload__image-wrapper {
        width: 118px;
        height: 118px;

        button {
          span {
            width: 23px;
            height: 23px;
            padding: 5px;
          }
        }
      }
      .profileName {
        margin: 16px 0;
        h3 {
          font-size: 15px;
          line-height: 21px;
        }
      }
      .logoutBtnWrap {
        font-size: 15px;
        line-height: 20px;
      }
    }

    .profileDetiails {
      margin-left: 45px;
    }

    .profileCard {
      padding: 38px;
      margin-bottom: 28px;

      li {
        margin-bottom: 28px;
      }

      .updateButton {
        padding: 12px 66px;
      }

      .accountTag {
        .accountDivision {
          h2 {
            font-size: 15px;
            line-height: 15px;
          }
        }
        .accountVerified {
          width: 170px;
        }
      }
    }

    .profileCount {
      li {
        width: 152px;
        padding: 20px 0;
        &::after {
          height: 95px;
        }
        h2 {
          font-size: 15px;
          line-height: 22px;
          margin-bottom: 13px;
        }
        h4 {
          font-size: 28px;
          line-height: 22px;
          margin-bottom: 13px;
        }
        h3 {
          font-size: 15px;
          line-height: 18px;
        }
      }
    }
  }
  @media only screen and (${device.lg}) {
    min-height: calc(100vh - 72px);
    margin-top: 70px;
    padding: 34px 0 18px;

    .breadcrumbsGrp {
      margin-bottom: 40px;
    }

    .profileImage {
      width: 11%;

      .upload__image-wrapper {
        width: 105px;
        height: 105px;

        button {
          span {
            width: 22px;
            height: 22px;
            padding: 4px;
            right: 1px;
          }
        }
      }
      .profileName {
        margin: 16px 0;
        h3 {
          font-size: 15px;
          line-height: 21px;
        }
      }
      .logoutBtnWrap {
        font-size: 15px;
        line-height: 20px;
      }
    }

    .profileDetiails {
      margin-left: 45px;
    }

    .profileCard {
      padding: 36px;
      margin-bottom: 26px;

      li {
        margin-bottom: 26px;
      }

      .updateButton {
        padding: 12px 60px;
      }

      .accountTag {
        .accountDivision {
          h2 {
            font-size: 14px;
            line-height: 14px;
          }
        }
        .accountVerified {
          width: 165px;
        }
      }
    }

    .profileCount {
      li {
        width: 152px;
        padding: 18px 0;
        &::after {
          height: 90px;
        }
        h2 {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 11px;
        }
        h4 {
          font-size: 26px;
          line-height: 20px;
          margin-bottom: 12px;
        }
        h3 {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
  @media only screen and (${device.md}) {
    min-height: calc(100vh - 68px);
    margin-top: 60px;
    padding: 32px 0 0px;

    .breadcrumbsGrp {
      margin-bottom: 30px;
    }

    .profileImage {
      width: 15%;

      .upload__image-wrapper {
        width: 105px;
        height: 105px;

        button {
          span {
            width: 22px;
            height: 22px;
            padding: 4px;
            right: 1px;
          }
        }
      }
      .profileName {
        margin: 15px 0;
        h3 {
          font-size: 14px;
          line-height: 18px;
        }
      }
      .logoutBtnWrap {
        font-size: 14px;
        line-height: 18px;
      }
    }

    .profileDetiails {
      width: 78%;
      margin-left: 45px;
    }

    .profileCard {
      padding: 36px;
      margin-bottom: 24px;

      li {
        margin-bottom: 24px;
      }

      .updateButton {
        padding: 12px 60px;
      }

      .accountTag {
        .accountDivision {
          h2 {
            font-size: 14px;
            line-height: 14px;
          }
        }
        .accountVerified {
          width: 165px;
        }
      }
    }

    .profileCount {
      li {
        width: 152px;
        padding: 20px 0px;
        &::after {
          height: 88px;
        }
        h2 {
          font-size: 13px;
          line-height: 18px;
          margin-bottom: 10px;
        }
        h4 {
          font-size: 26px;
          line-height: 18px;
          margin-bottom: 12px;
        }
        h3 {
          font-size: 13px;
          line-height: 16px;
        }
      }
    }
  }
  @media only screen and (${device.sm}) {
    margin-top: 60px;
    padding: 32px 0 0px;

    .breadcrumbsGrp {
      margin-bottom: 30px;
    }

    .profileWrap {
      flex-wrap: wrap;
      justify-content: center;
    }

    .profileImage {
      width: 100%;
      margin-bottom: 20px;

      .upload__image-wrapper {
        width: 105px;
        height: 105px;
        margin: 0 auto;
      }
      .profileName {
        margin: 15px 0;
        h3 {
          min-width: 200px;
          font-size: 14px;
          line-height: 18px;
        }
      }
      .logoutBtnWrap {
        text-align: center;
        button {
          width: 150px;
        }
        font-size: 14px;
        line-height: 18px;
      }
    }

    .profileDetiails {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column-reverse;
      margin-left: 0px;
    }

    .profileCard {
      padding: 36px;
      margin: 40px 0 0;

      li {
        margin-bottom: 24px;
      }

      .updateButton {
        padding: 12px 60px;
      }

      .accountTag {
        .accountDivision {
          h2 {
            font-size: 14px;
            line-height: 14px;
          }
        }
        .accountVerified {
          width: 165px;
        }
      }
    }

    .profileCount {
      width: 100%;
      li {
        width: 33%;
        padding: 20px 0px;
        &::after {
          height: 85px;
        }
        h2 {
          font-size: 13px;
          line-height: 18px;
          margin-bottom: 10px;
        }
        h4 {
          font-size: 26px;
          line-height: 18px;
          margin-bottom: 12px;
        }
        h3 {
          font-size: 13px;
          line-height: 16px;
        }
      }
    }
  }
  @media only screen and (${device.xs}) {
    margin-top: 55px;
    padding: 30px 0 0px;

    .breadcrumbsGrp {
      margin-bottom: 30px;
    }

    .profileImage {
      margin-bottom: 18px;

      .profileName {
        margin: 15px 0;
        h3 {
          min-width: 150px;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }

    .profileCard {
      padding: 36px 25px;
      margin: 35px 0 0;

      li {
        width: 100%;
        margin-bottom: 20px;
      }

      .updateButton {
        width: 100%;
        padding: 12px 0px;
      }

      .accountTag {
        .accountDivision {
          h2 {
            font-size: 14px;
            line-height: 14px;
          }
        }
        .accountVerified {
          width: 165px;
        }
      }
    }

    .profileCount {
      width: 100%;
      ul{
        width: 100%;
      }
      li {
        width: 33%;
        padding: 18px 0px;
        &::after {
          height: 75px;
        }
        h2 {
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 8px;
        }
        h4 {
          font-size: 22px;
          line-height: 16px;
          margin-bottom: 10px;
        }
        h3 {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }
`;
