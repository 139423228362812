import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1111111111;

  .loaderWithoutBg{
    background-color: none;
  }

  .loaderWithBg{
    background-color: rgba(0, 0, 0, 0.5);
  }

  .hasLoader {
    width: 100px;
    height: 100px;
    position: fixed;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 60%;
    bottom: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  span{
    top:45% !important;
  }
`;
