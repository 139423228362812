import { createGlobalStyle } from "styled-components";
import { device } from "./breakpoints";

const CarConnectionTheme = createGlobalStyle`

.backGroundOpacity{
  position: relative;
  overflow: hidden;
  .header{
    &::before {
    content: "";
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(5px);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9992;
    -webkit-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
  }
  }
}

.bodyFilterDrawer{
  overflow: hidden;
}

.heightClass{
  height: 64px;
}

.ReactModal__Body--open{
  overflow: hidden;
}

.ReactModal__Overlay{
  backGround: transparent !important;
  backdrop-filter: blur(8px);
  z-index: 9900;
}

.customModal{
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  z-index: 9999;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  backGround: ${({
    theme: {
      colors: { common },
    },
  }) => common.backGround};

  .modalHeader{
    margin: 18px 24px 0 24px;
    color: ${({
      theme: {
        colors: { common },
      },
    }) => common.secondary};
    text-align: end;

    h3{
      text-align: center;
      margin-top: 20px;
      font-weight: 600;
      font-size: 30px;
      line-height: 24px;
    }
    .cancelIcon{
      text-align: end;
      backGround: transparent; 
      cursor: pointer;
      svg{
        path{
          fill: ${({
            theme: {
              colors: { common },
            },
          }) => common.secondary};
        }
      }
    }
  }
}

:root{
  --swiper-navigation-size: 22px !important;
}

.detailsTab{
  .react-tabs__tab--selected{
    background: transparent !important;
    border-color: transparent;
    border-radius: 0;
  }
  .react-tabs__tab-list{
    border-bottom: none;
  }
}

.adsTabsListManageAds{
  .react-tabs__tab--selected{
    background: transparent !important;
    border-color: transparent;
    border-radius: 0;
  }
  .react-tabs__tab-list{
    border-bottom: none;
  }
}

.autoPartsType{
  .productList{
    justify-content: start !important;
  }
}

@media only screen and (${device.sm}) {
  .customModal{
    .modalHeader{
      margin: 16px 22px 0 22px;
      h3{
        margin-top: 18px;
        font-size: 24px;
        line-height: 22px;
      }
    }
  }
}

@media only screen and (${device.xs}) {
  .customModal{
    width: calc(100% - 30px);
    .modalHeader{
      margin: 14px 20px 0 20px;
      h3{
        margin-top: 15px;
        font-size: 18px;
        line-height: 20px;
      }
    }
  }
}
`;

export default CarConnectionTheme;
