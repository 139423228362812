import { device } from "style/breakpoints";
import styled from "styled-components";

export const Wrapper = styled.div`
  .breadList {
    display: flex;
    flex-wrap: wrap;
    li {
      display: flex;
      margin-right: 6px;

      a h4 {
        font-weight: 400;
        font-size: 16px;
        color: ${({
          theme: {
            colors: { auth },
          },
        }) => auth.textOpacityColor};
      }

      .bredcrumbsLabel {
        text-transform: capitalize;
        font-weight: 600;
        font-size: 16px;
        color: ${({
          theme: {
            colors: { auth },
          },
        }) => auth.secondary};
      }
    }
  }

  @media only screen and (${device.xl}) {
    .breadList {
      li {
        a h4 {
          font-size: 14px;
        }
        .bredcrumbsLabel {
          font-size: 14px;
        }
      }
    }
  }
  @media only screen and (${device.lg}) {
    .breadList {
      li {
        a h4 {
          font-size: 14px;
        }

        .bredcrumbsLabel {
          font-size: 14px;
        }
      }
    }
  }
  @media only screen and (${device.md}) {
    .breadList {
      li {
        a h4 {
          font-size: 13px;
        }

        .bredcrumbsLabel {
          font-size: 13px;
        }
      }
    }
  }
  @media only screen and (${device.sm}) {
    .breadList {
      li {
        a h4 {
          font-size: 12px;
        }

        .bredcrumbsLabel {
          font-size: 12px;
        }
      }
    }
  }
`;
