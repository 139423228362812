import { ILANGUAGE, ITHEMES } from './../Types/Common/Common.d';
import { ROUTES } from './routes';

export const THEMES: ITHEMES = {
    DARK: 'dark',
    LIGHT: 'light'
}

export const LANGUAGE: ILANGUAGE = {
    EN: 'en',
    AR: 'ar'
}

export const SOCIAL_MEDIA_TYPE = {
    facebook: 'facebook',
    google: 'google',
    apple: 'apple',
    normal: 'normal',
}

export const PRODUCTS = {
    used: {
        name: 'used',
        value: 1,
    },
    new: {
        name: 'new',
        value: 2
    },
    accessories: {
        name: 'accessories',
        value: 3,
    },
}

export const POST_TYPE_NAMES = {
    1: "used_cars",
    2: "new_cars",
    3: "auto_parts",
}

export const CAR_TYPE = {
    single: {
        name: 'single',
        value: 1
    },
    multiple: {
        name: 'multiple',
        value: 2,
    },
}

export const connectorStylesLight = {
    activeColor: '#C4C4C4;',
    completedColor: '#434343',
    disabledColor: ' #C4C4C4;',
    lineStyle: 'dashed',
    lineWidth: 2,
    size: 4,
    style: 'dashed'
};

export const connectorStylesDark = {
    activeColor: '#434343',
    completedColor: '#C4C4C4',
    disabledColor: ' #434343',
    lineStyle: 'dashed',
    lineWidth: 2,
    size: 4,
    style: 'dashed'
};

export const stepperstyleLight = {
    activeBgColor: '#43434333',
    activeTextColor: 'white',
    completedBgColor: 'green',
    completedTextColor: 'white',
    activeStepNumColor: 'white',
    activeStepIconColor: 'white',
    completedStepNumColor: 'white',
    completedStepIconColor: 'white',
    inactiveBgColor: 'red',
    inactiveTextColor: 'red',
    size: '30px',
    circleFontSize: '0px',
    labelFontSize: '10px',
    borderRadius: '50%',
    fontWeight: '200',
};

export const stepperstyleDark = {
    activeBgColor: '#C4C4C4',
    activeTextColor: 'black',
    completedBgColor: 'green',
    completedTextColor: 'black',
    activeStepNumColor: 'black',
    activeStepIconColor: 'white',
    completedStepNumColor: 'white',
    completedStepIconColor: 'white',
    inactiveBgColor: 'red',
    inactiveTextColor: 'red',
    size: '30px',
    circleFontSize: '0px',
    labelFontSize: '10px',
    borderRadius: '50%',
    fontWeight: '200'
};

export const FUEL_OPTIONS = [
    { value: 5, label: 'Petrol' },
    { value: 2, label: 'Diesel' },
    { value: 4, label: 'Electric' },
    { value: 3, label: 'Hybrid' },
    { value: 1, label: 'Gasoline' },
];

export const SEAT_OPTIONS = [
    { value: 2, label: '2' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 7, label: '7' },
    { value: 9, label: '9' },
];

export const STEERING_OPTIONS = [
    { value: 1, label: 'Left' },
    { value: 2, label: 'Right' },
]

export const WARRANTY_OPTIONS = [
    { value: 1, label: 'Yes' },
    { value: 2, label: 'No' },
    { value: 3, label: 'Applied' },
    { value: 4, label: 'Does Not Applied' },
];

export const DOORS_OPTIONS = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5+' },
];

export const CYLINDER_OPTIONS = [
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
    { value: 11, label: '11' },
    { value: 12, label: '12' },
];

export const TRANSMISSION_OPTIONS = [
    { value: 1, label: 'Manual' },
    { value: 2, label: 'Automatic' },
];

export const CARLIST = [
    {
        id: 0,
        carName: "Hyundai elentra",
        carType: "Sedan",
        carKilometer: 49000,
        carSpeed: 498,
        carDoor: 4,
        carPrice: "1,070,000",
        carImage: "/images/rangeRover.png",
    },
    {
        id: 1,
        carName: "Hyundai elentra",
        carType: "Sedan",
        carKilometer: 49000,
        carSpeed: 498,
        carDoor: 4,
        carPrice: "1,070,000",
        carImage: "/images/defender.png",
    },
    {
        id: 2,
        carName: "Hyundai elentra",
        carType: "Sedan",
        carKilometer: 49000,
        carSpeed: 498,
        carDoor: 4,
        carPrice: "1,070,000",
        carImage: "/images/rangeRover.png",
    },
    {
        id: 3,
        carName: "Hyundai elentra",
        carType: "Sedan",
        carKilometer: 49000,
        carSpeed: 498,
        carDoor: 4,
        carPrice: "1,070,000",
        carImage: "/images/rangeRover.png",
    },
    {
        id: 4,
        carName: "Hyundai elentra",
        carType: "Sedan",
        carKilometer: 49000,
        carSpeed: 498,
        carDoor: 4,
        carPrice: "1,070,000",
        carImage: "/images/car.png",
    },
];

export const CAR_NUMBER_OPTION = [
    { value: 2, label: "2", },
    { value: 3, label: "3", },
    { value: 4, label: "4", },
    { value: 5, label: "5", },
    { value: 6, label: "6", },
];

export const USE_CAR_LIST = [
    {
        id: 0,
        carName: "Hyundai elentra",
        carType: "Sedan",
        carKilometer: 49000,
        carSpeed: 498,
        carDoor: 4,
        carPrice: "1,070,000",
        carImage: "/images/rangeRover.png",
    },
    {
        id: 1,
        carName: "Hyundai elentra",
        carType: "Sedan",
        carKilometer: 49000,
        carSpeed: 498,
        carDoor: 4,
        carPrice: "1,070,000",
        carImage: "/images/defender.png",
    },
    {
        id: 2,
        carName: "Hyundai elentra",
        carType: "Sedan",
        carKilometer: 49000,
        carSpeed: 498,
        carDoor: 4,
        carPrice: "1,070,000",
        carImage: "/images/car.png",
    },
];

export const NEW_CAR_LIST = [
    {
        id: 0,
        carName: "Hyundai elentra",
        carType: "Sedan",
        carKilometer: 151,
        color: "red",
        engineDescription: 1199,
        carPrice: "1,070,000",
        carImage: "/images/defender.png",
    },
    {
        id: 1,
        carName: "Hyundai elentra",
        carType: "Sedan",
        carKilometer: 151,
        color: "red",
        engineDescription: 1199,
        carPrice: "1,070,000",
        carImage: "/images/rangeRover.png",
    },
    {
        id: 2,
        carName: "Hyundai elentra",
        carType: "Sedan",
        carKilometer: 151,
        color: "red",
        engineDescription: 1199,
        carPrice: "1,070,000",
        carImage: "/images/car.png",
    },
];

export const AUTOPART_CATEGORY = [
    { value: 1, label: 'Breaks' },
    { value: 2, label: 'Lighting' },
    { value: 3, label: 'Suspension' },
    { value: 4, label: 'Batteries' },
];

export const DEVICE_TYPE = {
    mobile: 1,
    web: 2,
}

export const PAGINATION_LIMITS = [10, 20, 50, 100]

export const STATE_REDIRECT = { isRedirect: true }

export const LOGIN_REDIRECTION_PATH = (path: string): string => ROUTES.signInOption + `?redirectionUrl=${path}`

export const POST_SORTING_TYPES = {
    DEFAULT: 'default',
    PRICE_ASC: 'price_asc',
    PRICE_DESC: 'price_desc',
    DATE_ASC: 'date_asc',
    DATE_DESC: 'date_desc'
}

export const SAVED_SEARCH_SORTING_TYPES = {
    DEFAULT: 'default',
    DATE_ASC: 'date_asc',
    DATE_DESC: 'date_desc'
}
export const FILTER_TYPE = {
    RANGE: "RANGE",
    ARRAY: "ARRAY",
};

export const GENDER_OPTION = [
    {
        value: 1,
        label: "Male",
    },
    {
        value: 2,
        label: "Female",
    },
    {
        value: 3,
        label: "Other",
    },
];

export const POST_STATUS = {
    1: "Active",
    2: "In Active",
    3: "Sold",
};
export const POST_STATUS_FILTERS = [
    { value: 1, label: "Active", },
    { value: 3, label: "Sold", },
];