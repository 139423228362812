import { useDispatch } from "react-redux";
import { Wrapper } from "./style";
import { displayFooter } from "services/AuthSlice";
import ComingSoonIcon from "assets/svg/comingSoon";

const ComingSoon = () => {
  const dispatch = useDispatch();
  dispatch(displayFooter(false));

  return (
    <>
      <Wrapper className="comingSoonWrap">
        <div className="comingsoonFigure">
          <ComingSoonIcon />
        </div>
        <h1>Coming Soon !!</h1>
      </Wrapper>
    </>
  );
};

export default ComingSoon;
