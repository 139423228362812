import { IAutoPart, IDraftsDetail, IPackageDetail } from './../../Types/Entity/PostEntity';
import { DRAFT_META_DETAIL_F, DRAFT_DETAIL_S, DRAFT_DETAIL_F, DRAFT_PACKAGE_S, DRAFT_PACKAGE_F, DRAFT_SUMMARY_S, DRAFT_SUMMARY_F, ACCESSORY_REVIEW_S, ACCESSORY_REVIEW_F, ACCESSORY_PACKAGE_S, GET_ACCESSORY_S, GET_ACCESSORY_F, ACCESSORY_PACKAGE_F, } from './../../constants/redecuerType';
import { createSlice } from "@reduxjs/toolkit";
import { DRAFT_META_DETAIL_S } from 'constants/redecuerType';

interface IStepperMetaData {
  numberOfCars: number
  activeCarIndex: number,
  activeStepIndex: number,
  isSingleCarFlow: boolean,
  lastActiveCarIndex: number,
  lastActiveStepIndex: number,
}
export interface IPostSlice {
  draftDetail: IDraftsDetail;
  stepperMetaData: IStepperMetaData;
  draftPackageDetail: IPackageDetail[];
  draftSummaryDetail: IDraftsDetail;
  accessoryReview: IAutoPart;
  accessoryPackage: IPackageDetail[];
  accessoryData: IAutoPart;
}

const initialState: IPostSlice = {
  draftDetail: {},
  stepperMetaData: {
    activeCarIndex: 1,
    activeStepIndex: 1,
    isSingleCarFlow: true,
    numberOfCars: 1,
    lastActiveCarIndex: 1,
    lastActiveStepIndex: 1
  },
  draftPackageDetail: [],
  draftSummaryDetail: {},
  accessoryReview: {},
  accessoryPackage: [],
  accessoryData: {},
};

// Reducer
const postSlice = createSlice({
  name: "post",
  initialState: initialState,
  reducers: {
    resetPostStepper: (state, action) => {
      state.stepperMetaData.activeCarIndex = 1;
      state.stepperMetaData.activeStepIndex = 1;
      state.stepperMetaData.numberOfCars = 1;
      state.stepperMetaData.isSingleCarFlow = true;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(DRAFT_META_DETAIL_S, (state, action: any) => {
      state.stepperMetaData = {
        ...state.stepperMetaData,
        ...action.payload,
        isSingleCarFlow: action.payload?.numberOfCars > 1 ? false : true,
      };
    });
    builder.addCase(DRAFT_META_DETAIL_F, (state) => {
      state.stepperMetaData = {
        activeCarIndex: 1,
        activeStepIndex: 1,
        isSingleCarFlow: true,
        numberOfCars: 1,
        lastActiveCarIndex: 1,
        lastActiveStepIndex: 1
      }
    });
    builder.addCase(DRAFT_DETAIL_S, (state, action: any) => {
      state.draftDetail = {
        ...action.payload
      };
    });
    builder.addCase(DRAFT_DETAIL_F, (state) => {
      state.draftDetail = {}
    });
    builder.addCase(DRAFT_PACKAGE_S, (state, action: any) => {
      state.draftPackageDetail = [...action.payload];
    });
    builder.addCase(DRAFT_PACKAGE_F, (state) => {
      state.draftPackageDetail = []
    });
    builder.addCase(DRAFT_SUMMARY_S, (state, action: any) => {
      state.draftSummaryDetail = action.payload;
    });
    builder.addCase(DRAFT_SUMMARY_F, (state) => {
      state.draftSummaryDetail = {}
    });
    builder.addCase(ACCESSORY_REVIEW_S, (state, action: any) => {
      state.accessoryReview = action.payload;
    });
    builder.addCase(ACCESSORY_REVIEW_F, (state) => {
      state.accessoryReview = {};
    });
    builder.addCase(ACCESSORY_PACKAGE_S, (state, action: any) => {
      state.accessoryPackage = action.payload;
    });
    builder.addCase(ACCESSORY_PACKAGE_F, (state) => {
      state.accessoryPackage = [];
    });
    builder.addCase(GET_ACCESSORY_S, (state, action: any) => {
      state.accessoryData = action.payload;
    });
    builder.addCase(GET_ACCESSORY_F, (state) => {
      state.accessoryData = {};
    });
  },
});

export const { resetPostStepper } = postSlice.actions;
export default postSlice.reducer;
