import { configureStore } from '@reduxjs/toolkit'
import reduxApiMiddleware from './Middleware'
import thunk from 'redux-thunk'
import AuthSlice from './AuthSlice'
import { TypedUseSelectorHook, useDispatch, useSelector as useReduxSelector } from 'react-redux'
import postSlice from './postSlice'
import MetaSlice from './MetaSlice'
import UserDataSlice from './UserDataSlice'

export const store = configureStore({
    reducer: {
        auth: AuthSlice,
        post: postSlice,
        meta: MetaSlice,
        userData: UserDataSlice,
    },
    middleware: [thunk, reduxApiMiddleware]
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppSelector = typeof store.getState
export const useAppDispatch: () => AppDispatch = useDispatch // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useReduxSelector
