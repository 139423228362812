export interface IThemProperties {

  colors: {
    common: {
      white: string;
      black: string;
      primary: string;
      bgColor: string;
      backGround: string;
      secondary: string;
      btnColor: string;
      textOpacityColor: string;
    };
    auth: {
      white: string;
      black: string;
      primary: string;
      bgColor: string;
      backGround: string;
      secondary: string;
      btnColor: string;
      textOpacityColor: string;
      secondaryTextOpacity: string;
    };
  };
}

const lightCommonProperties = {
  white: "#ffffff",
  black: "#000000",
  primary: "#FCFCFC",
  bgColor: "#ffffff",
  backGround: "#ffffff",
  backGround1: '#E3E3E3',
  backGround2: '#ffffff',
  backGround3: '#F8F8F8',
  backGround4: '#F5F5F6',
  secondary: '#434343',
  SecondaryOpacity: "#43434333",
  btnColor: '#434343',
  btnBgColor: '#FFFFFF',
  textOpacityColor: "#757575",
  blackProp: '#000000',
  whiteProp: '#282727',
  opacityProp: '#757575',
  cardBackGround: '#424243',
  btnText: '#122A46',
  labelText: '#90A3BF',
  textColor: '#232738',
  btnTextColor: '#434343',
  cardBackGround1: "#EDEDED",
  textColor1: "#000000",
  redColor: '#FF0000B2',
  secondaryTextOpacity: '#00000033',
  redBg: "#FFEAEA",
  blueBg: '#DAEDFF',
  gray: 'rgba(0, 0, 0, 0.7)'
};

const darkCommonProperties = {
  white: "#ffffff",
  black: "#000000",
  primary: "#232323",
  bgColor: "#1F1F1F",
  backGround: "#282727",
  backGround1: '#282727',
  backGround2: '#202020',
  backGround3: '#202020',
  backGround4: '#1F1F1F',
  secondary: '#ffffff',
  secondaryOpacity: '#ffffff33',
  btnColor: '#434343',
  btnBgColor: '#434343',
  textOpacityColor: "#ffffffb3",
  blackProp: '#ffffff',
  whiteProp: '#ffffff',
  opacityProp: '#757575',
  cardBackGround: '#282727',
  btnText: '#ffffff',
  labelText: '#90A3BF',
  textColor: '#ffffff',
  btnTextColor: '#8c8c8c',
  cardBackGround1: "#2E2E2E",
  textColor1: "#ffffff",
  redColor: "#FF6C6C",
  secondaryTextOpacity: '#ffffff33',
  redBg: "#FFEAEA",
  blueBg: '#DAEDFF',
  gray: 'rgba(255, 255, 255, 0.7)'
};

export const light: IThemProperties = {
  colors: {
    common: { ...lightCommonProperties },
    auth: { ...lightCommonProperties },
  },
};

export const dark: IThemProperties = {
  colors: {
    common: { ...darkCommonProperties },
    auth: { ...darkCommonProperties },
  },
};
