import { ISignUpResponse, ILoginResponse } from './../../Types/Entity/AuthEntity';
import { REFRESH_TOKEN_S, REFRESH_TOKEN_F } from './../../constants/redecuerType';
import { THEMES, LANGUAGE } from './../../constants/common';
import { ITHEMES } from './../../Types/Common/Common.d';
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { LS_AUTHORED, LS_USER } from "../../constants";
import { LOGIN_F, LOGIN_S, SIGNUP_S } from 'constants/redecuerType';

export interface IAuthSlice {
  isLoading: boolean;
  isLoggedIn: boolean;
  userData: ILoginResponse;
  theme: ITHEMES,
  language: string,
  isSuperAdmin?: boolean;
  signupData: ISignUpResponse;
  showFooter: boolean;
}

const initialState: IAuthSlice = {
  isLoading: false,
  isLoggedIn: false,
  theme: THEMES.LIGHT,
  language: LANGUAGE.EN,
  userData: {
    email: '',
    emailConfirmed: '',
    expiration: '',
    fullName: '',
    phoneNumber: '',
    refreshToken: '',
    token: '',
  },
  signupData: {
    email: '',
    emailConfirmed: '',
    expiration: '',
    fullName: '',
    phoneNumber: '',
    refreshToken: '',
    token: '',
  },
  showFooter: true
};

// Reducer
const loginSlice = createSlice({
  name: "login",
  initialState: initialState,
  reducers: {
    authSuccess: (state, action) => {
      axios.defaults.headers.common["Authorization"] = `Bearer ${action.payload?.token}`;

      localStorage.setItem(LS_AUTHORED, JSON.stringify(action.payload?.token));
      localStorage.setItem(LS_USER, JSON.stringify(action.payload));

      state.isLoggedIn = true;
      state.userData = action.payload;
    },
    authFail: (state, action) => {
      delete axios.defaults.headers.common['Authorization']

      localStorage.removeItem(LS_AUTHORED);
      localStorage.removeItem(LS_USER);

      state.isLoggedIn = false;
      state.userData = {
        email: '',
        emailConfirmed: '',
        expiration: '',
        fullName: '',
        phoneNumber: '',
        refreshToken: '',
        token: '',
      };
    },
    loaderChange: (state, action) => {
      state.isLoading = action.payload;
    },
    themeChange: (state, action) => {
      state.theme = action.payload
    },
    languageChange: (state, action) => {
      state.language = action.payload
    },
    displayFooter: (state, action) => {
      state.showFooter = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(LOGIN_S, (state, action: any) => {
      // Default header for auth
      axios.defaults.headers.common["Authorization"] = `Bearer ${action.payload?.token}`;
      state.signupData = action.payload;
    });
    builder.addCase(LOGIN_F, (state, action: any) => {
      // remove items on logout
      delete axios.defaults.headers.common['Authorization']
      localStorage.removeItem(LS_AUTHORED);
      localStorage.removeItem(LS_USER);

      state.userData = action.payload;
      state.isLoggedIn = false;
    });
    builder.addCase(SIGNUP_S, (state, action: any) => {
      // Default header for auth
      axios.defaults.headers.common["Authorization"] = `Bearer ${action.payload?.token}`;
      state.signupData = action.payload;
    });
    builder.addCase(REFRESH_TOKEN_S, (state, action: any) => {
      // Default header for auth
      axios.defaults.headers.common["Authorization"] = `Bearer ${action.payload?.accessToken}`;
      localStorage.setItem(LS_AUTHORED, JSON.stringify(action.payload?.accessToken));

      if (localStorage.getItem(LS_USER)) {
        const ls_data = JSON.parse(localStorage.getItem(LS_USER) || '')
        ls_data.refreshToken = action.payload?.refreshToken
        localStorage.setItem(LS_USER, JSON.stringify(ls_data))
      }

      state.isLoggedIn = true;
    });
    builder.addCase(REFRESH_TOKEN_F, (state, action: any) => {
      // remove items on logout
      delete axios.defaults.headers.common['Authorization']
      localStorage.removeItem(LS_AUTHORED);
      localStorage.removeItem(LS_USER);

      state.userData = action.payload;
      state.isLoggedIn = false;
    });
  },
});

export const { loaderChange, themeChange, languageChange, displayFooter, authSuccess, authFail } = loginSlice.actions;
export default loginSlice.reducer;
